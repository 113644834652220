import { combineReducers,createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import {currentRouteReducer,authInfoReducer,
  createdBranchReducer,updatedBranchReducer,branchDisableRestoreReducer,
  createdWarehouseReducer,
  updatedWarehouseReducer,
  warehouseDisableRestoreReducer,
  collectionReducer
} from './common_reducers';

const reducers = combineReducers({
    currentRouteReducer,
    authInfoReducer,
    createdBranchReducer,
    updatedBranchReducer,
    branchDisableRestoreReducer,
    createdWarehouseReducer,
    updatedWarehouseReducer,
    warehouseDisableRestoreReducer,
    collectionReducer
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist:['currentRouteReducer',
  'createdBranchReducer','updatedBranchReducer','branchDisableRestoreReducer',
  'createdWarehouseReducer','updatedWarehouseReducer','warehouseDisableRestoreReducer',
  'collectionReducer'
]
}
 

const persistedReducer = persistReducer(persistConfig, reducers)
const store = createStore(persistedReducer)
const persistor = persistStore(store)
export  {store,persistor}