import React, { useState,useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import '../../commons/voucher.css'
import commaNumber from 'comma-number';
import Grid from '@material-ui/core/Grid';

let format = commaNumber.bindWith(',', '.')
const VoucherBody = ({manufacturingOrderData,authInfo})=>{
    const classes = useStyles();
  

         let [manufacturingOrder,manufacturingOrderSet] = useState([])
          let [manufacturingOrderDetails,manufacturingOrderDetailsSet] = useState([])
          let [mciDetails,mciDetailsSet] = useState([])
          

          let [is_serial,is_serial_set] = useState('')
          let [is_cal_type,is_cal_type_set] = useState('')

        useEffect(()=>{
            manufacturingOrderSet(manufacturingOrderData)
            
            is_serial_set(authInfo.is_serial)
            is_cal_type_set(manufacturingOrderData.is_cal_type)

            if(manufacturingOrderData.miDetails!=undefined){
                manufacturingOrderDetailsSet(manufacturingOrderData.miDetails)
            }

            if(manufacturingOrderData.mciDetails!=undefined){
                mciDetailsSet(manufacturingOrderData.mciDetails)
            }
            },[manufacturingOrderData])

         


      return(
          <>

          <Grid container>
                <Grid item xs={6} sm={6} style={{paddingRight: '10px'}}>
                <h4 style={{margin: '0',color: '#308f00'}}>Production Items</h4>
                <table className={'invoice-table'}> 
          <thead>
              <tr>
                <td  align="left"  width="1%">SL</td>
                <td align="left" width="15%"> Item Name</td>
                {
                is_serial=='yes'?(<>
              <td align="left"   width="15%">Serials</td>

                </>):''
              }
              
              {
                authInfo.is_warehouse == 'yes'?(<>
                  <td align="left"   width="15%">Warehouse</td>
                </>):''
              }
              <td align="right"  width="10%">Rate</td>
              <td align="right"  width="10%">Per</td>
              <td align="right"  width="15%"> QTY</td>
              {
                manufacturingOrder.status != 'a'?(<><td align="right"  width="15%">PURCHASED QTY</td></>):''
              }
              

            
              <td align="right" width="10%">Total</td>
              </tr>
                
             
          </thead>
          <tbody>
        
                    {
                        manufacturingOrderDetails.map((item,ind)=>(<>
                               <tr>
                                 <td  style={{textAlign:'left'}}>{parseFloat(1)+ind}</td>
                                 <td  style={{textAlign:'left'}}>{item.item_name}</td>
                                 {
                                    is_serial == 'yes'?(<>
                                    <td align="left"  width="15%">
                                    {
                                        item.is_serial == 'yes'?(<>
                                            {
                                            <>
                                            ({item.serials.map((serial)=>(
                                        <> <span>{serial.serial_number}</span>, </> 
                                        ))})
                                        </>
                                         }
                                        </>):''
                                    }
                                  </td>
                    
                                   </>):''
                                  }

                  {
                   authInfo.is_warehouse =='yes'?(<>
                          <td align="left">{item.warehouse_name}</td>
                   </>):''
                 }
                  <td align="right">{format(parseFloat(item.item_rate).toFixed(2))}</td>
                  <td align="right">{item.per_unit_symbol}</td>
                  <td align="right">{item.qty_display}</td>
                  
                  {
                   manufacturingOrder.status != 'a'?(<><td align="right"  >{item.done_qty_display}</td></>):''
                 }
               
                
                  <td align="right">{format(parseFloat(item.item_total).toFixed(2))}</td>

                                </tr>
                        </>))
                    }
          
                
           

               
              

              
          </tbody>
        </table>
                </Grid>

                <Grid item xs={6} sm={6} style={{paddingLeft: '10px'}}>
                <h4 style={{margin: '0',color: '#990000'}}>Consume Items</h4>
                <table className={'invoice-table'}> 
          <thead>
              <tr>
                <td  align="left"  width="1%">SL</td>
                <td align="left" width="15%"> Item Name</td>
                {
                is_serial=='yes'?(<>
              <td align="left"   width="15%">Serials</td>

                </>):''
              }
              
              {
                authInfo.is_warehouse == 'yes'?(<>
                  <td align="left"   width="15%">Warehouse</td>
                </>):''
              }
              <td align="right"  width="10%">Rate</td>
              <td align="right"  width="10%">Per</td>
              <td align="right"  width="15%"> QTY</td>
              {
                manufacturingOrder.status != 'a'?(<><td align="right"  width="15%">PURCHASED QTY</td></>):''
              }
              

            
              <td align="right" width="10%">Total</td>
              </tr>
                
             
          </thead>
          <tbody>
        
                    {
                        mciDetails.map((item,ind)=>(<>
                               <tr>
                                 <td  style={{textAlign:'left'}}>{parseFloat(1)+ind}</td>
                                 <td  style={{textAlign:'left'}}>{item.item_name}</td>
                                 {
                                    is_serial == 'yes'?(<>
                                    <td align="left"  width="15%">
                                    {
                                        item.is_serial == 'yes'?(<>
                                            {
                                            <>
                                            ({item.serials.map((serial)=>(
                                        <> <span>{serial.serial_number}</span>, </> 
                                        ))})
                                        </>
                                         }
                                        </>):''
                                    }
                                  </td>
                    
                                   </>):''
                                  }

                  {
                   authInfo.is_warehouse =='yes'?(<>
                          <td align="left">{item.warehouse_name}</td>
                   </>):''
                 }
                  <td align="right">{format(parseFloat(item.raw_item_rate).toFixed(2))}</td>
                  <td align="right">{item.per_unit_symbol}</td>

                  <td align="right">{item.qty_display}</td>
                  
                  {
                   manufacturingOrder.status != 'a'?(<><td align="right"  >{item.done_qty_display}</td></>):''
                 }
               
                
                  <td align="right">{format(parseFloat(item.raw_item_total).toFixed(2))}</td>

                                </tr>
                        </>))
                    }
          
                
           

               
              

              
          </tbody>
        </table>
                </Grid>
          </Grid>

        
          </>
      )
}


const useStyles = makeStyles((theme) => ({
    
    
  }));

export default VoucherBody;
