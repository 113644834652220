import React, { useState,useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import '../../commons/voucher.css'
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')
const VoucherBody = ({salesOrderData,authInfo})=>{
    const classes = useStyles();
  

         let [salesOrder,salesOrderSet] = useState([])
          let [salesOrderDetails,salesOrderDetailsSet] = useState([])

          let [is_serial,is_serial_set] = useState('')
          let [is_cal_type,is_cal_type_set] = useState('')

        useEffect(()=>{
            salesOrderSet(salesOrderData)
            
            is_serial_set(salesOrderData.is_serial)
            is_cal_type_set(salesOrderData.is_cal_type)

            if(salesOrderData.details!=undefined){
                salesOrderDetailsSet(salesOrderData.details)
            }
            },[salesOrderData])

         


      return(
          <>
        <table className={'invoice-table'}> 
          <thead>
              <tr>
                <td  align="left"  width="1%">SL</td>
                <td align="left" width="15%"> Item Name</td>
                {
                is_serial=='yes'?(<>
              <td align="left"   width="15%">Serials</td>

                </>):''
              }
              
              {
                authInfo.is_warehouse == 'yes'?(<>
                  <td align="left"   width="15%">Warehouse</td>
                </>):''
              }
              <td align="right"  width="10%">Rate</td>
              <td align="right"  width="15%">Per</td>
              <td align="right"  width="15%"> QTY</td>
            
              

              {
                is_cal_type == 'individual'?(<>
                     <td align="right"  width="5%">Discount%</td>
                     <td align="right"  width="5%">Vat%</td>
                </>):''
              }

              <td align="right" width="10%">Total</td>
              </tr>
                
             
          </thead>
          <tbody>
        
                    {
                        salesOrderDetails.map((item,ind)=>(<>
                               <tr>
                                 <td  style={{textAlign:'left'}}>{parseFloat(1)+ind}</td>
                                 <td  style={{textAlign:'left'}}>{item.item_name}</td>
                                 {
                                    is_serial == 'yes'?(<>
                                    <td align="left"  width="15%">
                                    {
                                        item.is_serial == 'yes'?(<>
                                            {
                                            <>
                                            ({item.serials.map((serial)=>(
                                        <> <span>{serial.serial_number}</span>, </> 
                                        ))})
                                        </>
                                         }
                                        </>):''
                                    }
                                  </td>
                    
                                   </>):''
                                  }

                  {
                   authInfo.is_warehouse =='yes'?(<>
                          <td align="left">{item.warehouse_name}</td>
                   </>):''
                 }
                  <td align="right">{format(parseFloat(item.item_rate).toFixed(2))}</td>
                  <td align="right">{item.per_unit_symbol}</td>
                  <td align="right">{item.qty_display}</td>
                  
                 
                  {
                    is_cal_type == 'individual' ? (<>
                         <td align="right">{item.item_discount_per}%</td>
                          <td align="right">{item.item_tax_per}%</td>
                    </>):''
                  }
                
                  <td align="right">{format(parseFloat(item.item_total).toFixed(2))}</td>

                                </tr>
                        </>))
                    }
          
                
           

               
              

              
          </tbody>
        </table>
          </>
      )
}


const useStyles = makeStyles((theme) => ({
    
    
  }));

export default VoucherBody;
