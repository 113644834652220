import React,{useState,useEffect,useRef} from 'react';
import {connect} from 'react-redux';

import {currentRouteSet} from '../../actions/actions';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid'; 
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import {pathSpliter,_p,sleep,dateTimeFormat,dateFormat,currentDateTime,getDateTimeFromISODT,currentDateStartTime,
  currentDateEndTime,currentYear,currentMonth} from '../../lib/functions'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DdatateIcon from '@material-ui/icons/Delete';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DateFnsUtils from '@date-io/date-fns'; // choose your libs
import {API_URL} from '../../config.json';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import _ from 'lodash';
import ReactToPrint from "react-to-print";
import PrintIcon from '@material-ui/icons/Print';
import InstitutionProfile from '../commons/institution_profile'
import '../commons/commons.css'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import years from '../../years.json'
import months from '../../months.json'

import swal from 'sweetalert';
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')
const DebtorReceiptRecord = ({location,currentRoute,currentRouteSet,authInfo})=>{
    const classes = useStyles();
    useEffect(()=>{
        currentRouteSet(pathSpliter(location.pathname,1));
        getSearchResult()
    },[]);
    let [items,itemsSet] = useState([]);

    let [employees,employeesSet] = useState([]);
    let [locations,locationsSet] = useState([]);
    let [users,usersSet] = useState([]);
    let [reportLoading,reportLoadingSet] = useState(false);
    
    let [selectedFilterType,selectedFilterTypeSet] = useState({filterType:'All'})
    let [selectedEmployee,selectedEmployeeSet] = useState(null)
    let [selectedType,selectedTypeSet] = useState(null)
    let [selectedUser,selectedUserSet] = useState(null)
    let [selectedRecordType,selectedRecordTypeSet] = useState({recordType:'without details'})

    
    let [filterResult,filterResultSet] = useState([]);
    let [filterResultDetails,filterResultDetailsSet] = useState([]);
    let [details,detailsSet] = useState([]);

    let [selectedYear,selectedYearSet] = useState({year:`${currentYear()}`});
    let [selectedMonth,selectedMonthSet] = useState({month:`${currentMonth()}`});
    

    let [print,printSet] = useState(false);
    let [loadingEmployees,loadingEmployeesSet] = useState(false);
    let [employee_name,employee_name_set] = useState('');
    
    let [recordTypes,recordTypesSet] = useState([{recordType:'without details'},{recordType:'with details'}])
   

   
    
        const [attendance_date, attendance_date_set] = useState(currentDateTime);

      



    useEffect(()=>{
          loadingEmployeesSet(true)
          axios.post(`${API_URL}/api/get-employees-by-search`,{query:employee_name.trim()},{headers:{'auth-token':authInfo.token}}).then(res=>{
            employeesSet(res.data)
            loadingEmployeesSet(false)
          })
          
    },[employee_name])






    const getLocations = async ()=>{
      await axios.post(`${API_URL}/api/get-locations`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
        locationsSet(res.data)
    })
    }


      const getUsers = async ()=>{
        await axios.post(`${API_URL}/api/get-users`,{'select-type': "active"},{headers:{'auth-token':authInfo.token}}).then(res=>{
          usersSet(res.data.message)
      })
      }

    let [byDateTime,byDateTimeSet] = useState({
          dateTimeFrom: currentDateStartTime(),
          dateTimeTo:currentDateEndTime()
    })


 
    useEffect(()=>{ 

        if(selectedFilterType != null && selectedFilterType.filterType=='By User' && users.length == 0 ){
            getUsers();
        }

        if(selectedFilterType != null && locations.length == 0 &&  (selectedFilterType.filterType=='By Type'  || selectedFilterType.filterType=='By Item')){
          getLocations();
        }

        filterResultSet([])
        filterResultDetailsSet([])
        detailsSet([])

        selectedEmployeeSet(null)
        selectedTypeSet(null)
        selectedUserSet(null)
       


    },[selectedFilterType,selectedRecordType]);


    let [filterTypes,filterTypesSet] = useState([{filterType:'All'},
    // {filterType:'By Employee'},
    ]);


    useEffect(()=>{
      getSearchResult()
    },[selectedYear,selectedMonth,attendance_date])

    
    let getSearchResult = async ()=>{

      if(selectedFilterType == null){
        swal({
          title:'Select Filter Type',
          icon:'warning'
        })
        return false
      }

      if(selectedYear == null){
        swal({
          title:'Select  Year',
          icon:'warning'
        })
        return false
      }
  
      if(selectedMonth == null){
        swal({
          title:'Select  Month',
          icon:'warning'
        })
        return false
      }
  
     
        let url =  `${API_URL}/api/get-attendance-employees`;

          

   
              
        let reqPayload = {
            selectedFilterType: selectedFilterType != null?selectedFilterType.filterType:null,
            typeId: selectedType != null? selectedType.pay_type:null,
            employeeId: selectedEmployee != null? selectedEmployee.employee_id:null,
            year : selectedYear.year,
            month : selectedMonth.month,
            attendanceDate: attendance_date,
        }

        
        reportLoadingSet(true)
        await axios.post(`${url}`,reqPayload,{headers:{'auth-token':authInfo.token}}).then(res=>{
             printSet(true)
             reportLoadingSet(false)
              

              filterResultSet(res.data);

        })



    }


    const attendanceAllAction = async (action)=>{
      if(selectedYear == null){
        swal({
          title:'Select  Year',
          icon:'warning'
        })
        return false
      }
  
      if(selectedMonth == null){
        swal({
          title:'Select  Month',
          icon:'warning'
        })
        return false
      }

      let data = {}
      data.year = selectedYear.year
      data.month = selectedMonth.month
      data.attendance_date = attendance_date 
      data.action = action 
      data.month_day = moment(attendance_date).daysInMonth();


    
      await axios.post(`${API_URL}/api/save-all-attendance`,data,{headers:{'auth-token':authInfo.token}}).then(res=>{
        getSearchResult()
      })

    }
 
 const attendanceAction = async (data,action)=>{
    console.log(data)

    if(selectedYear == null){
      swal({
        title:'Select  Year',
        icon:'warning'
      })
      return false
    }

    if(selectedMonth == null){
      swal({
        title:'Select  Month',
        icon:'warning'
      })
      return false
    }

    data.year = selectedYear.year
    data.month = selectedMonth.month
    data.attendance_date = attendance_date 
    data.action = action 
    data.month_day = moment(attendance_date).daysInMonth();


    await axios.post(`${API_URL}/api/save-attendance`,data,{headers:{'auth-token':authInfo.token}}).then(res=>{
      getSearchResult()
    })
 }


    let ReportDom = React.forwardRef( (props,ref) =>{
        return(
          <div ref={ref} >
                        <InstitutionProfile />

<div style={{clear:'both'}}></div>
<Paper style={{paddingLeft:'10px',paddingRight:'10px'}} className="print-source">
<p style={{width:'100%',textAlign:'center',fontWeight:'bold'}}>Attendance Entry</p>
<div style={{float:'left',width:'50%',textAlign:'left',margin:0,padding:0,fontSize:'16px'}}>

<p style={{margin:'0px'}}>{selectedFilterType != null ?`Filter Type : ${selectedFilterType.filterType}`:''} </p>
<p style={{margin:'0px'}}>{selectedFilterType != null && (selectedFilterType.filterType == 'By Employee' ||  selectedFilterType.filterType == 'By Item')  ?`Employee Name : ${selectedEmployee!=null?selectedEmployee.employee_name:''}`:''}</p>

  
<p style={{margin:'0px'}}>{selectedFilterType != null && (selectedFilterType.filterType == 'By Type' ||  selectedFilterType.filterType == 'By Item')?`Location : ${selectedType!=null?selectedType.pay_type:''}`:''}</p>
<p style={{margin:'0px'}}>{selectedFilterType != null && selectedFilterType.filterType == 'By User'?`User : ${selectedUser!=null?selectedUser.user_name:''}`:''}</p>

</div>

<div style={{width:'10%'}}></div>

<div style={{float:'right',width:'40%',textAlign:'left',margin:0,padding:0,fontSize:'16px'}}>
<p style={{margin:'0px'}}>Statement  :  { moment(byDateTime.dateTimeFrom).format(dateFormat)  } - { moment(byDateTime.dateTimeTo).format(dateFormat) }</p>
</div>
 


</Paper> 

{
  (selectedRecordType !=null && selectedFilterType != null &&   filterResult.length>0 &&  selectedRecordType.recordType=='without details' && (selectedFilterType.filterType == 'All'  || selectedFilterType.filterType == 'By User' ) && (selectedFilterType.filterType != 'By Employee' && selectedFilterType.filterType != 'By Type'))?(
    <Paper className={classes.paper}  style={{paddingLeft:'10px',paddingRight:'10px',marginTop:'1px',marginBottom:'1px'}}>
    
    {/* sale Record  without details */} 
    <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table" className="report-dom">
        <TableHead>
          <TableRow> 
            <TableCell align="left"  width="3%">SL</TableCell>
            <TableCell align="left"  width="5%">Employee Name</TableCell>
            <TableCell align="left"  width="6%">Department</TableCell>
            <TableCell align="left"  width="6%">Designation</TableCell>
            <TableCell align="left"  width="10%">Contact</TableCell>
            <TableCell align="right" width="10%"><CheckCircleOutlinedIcon style={{cursor:'pointer'}} onClick={()=>attendanceAllAction('precent')} /> Present</TableCell> 
            <TableCell align="right" width="10%"> <CheckCircleOutlinedIcon style={{cursor:'pointer'}} onClick={()=>attendanceAllAction('absence')} /> Absence</TableCell> 
            <TableCell align="right" width="15%"> <CheckCircleOutlinedIcon style={{cursor:'pointer'}} onClick={()=>attendanceAllAction('leave_with_pay')}  /> Leave with Pay</TableCell> 
            <TableCell align="right" width="15%"> <CheckCircleOutlinedIcon style={{cursor:'pointer'}} onClick={()=>attendanceAllAction('leave_without_pay')} /> Leave without Pay</TableCell> 
          </TableRow>
        </TableHead>
        <TableBody>
            {
              filterResult.map((data,index)=>(
                <TableRow  key={index}> 
                <TableCell  align="left">{index+parseFloat(1)}</TableCell>
                <TableCell align="left">{data.employee_name}</TableCell> 
                <TableCell align="left">{data.department_name}</TableCell> 
                <TableCell align="left">{data.designation_name}</TableCell> 
                <TableCell align="left">{data.contact_no} </TableCell> 

                <TableCell align="left" style={{textAlign:'center'}}>
                  
                  {
                    data.attendance == 'precent'?(
                      <>
                        <span className="yes" onClick={()=>attendanceAction(data,'precent')}>YES</span>
                      </>
                    ):<>
                       <span className="no" onClick={()=>attendanceAction(data,'precent')}>NO</span>
                    </>
                  }
                  </TableCell> 
                <TableCell align="left" style={{textAlign:'center'}}>
                {
                    data.attendance == 'absence'?(
                      <>
                        <span className="yes" onClick={()=>attendanceAction(data,'absence')}>YES</span>
                      </>
                    ):<>
                       <span className="no" onClick={()=>attendanceAction(data,'absence')}>NO</span>
                    </>
                  }
                  </TableCell> 
                <TableCell align="left" style={{textAlign:'center'}}>
                {
                    data.attendance == 'leave_with_pay'?(
                      <>
                        <span className="yes" onClick={()=>attendanceAction(data,'leave_with_pay')}>YES</span>
                      </>
                    ):<>
                       <span className="no" onClick={()=>attendanceAction(data,'leave_with_pay')}>NO</span>
                    </>
                  }
                  </TableCell> 
                <TableCell align="left" style={{textAlign:'center'}}>
                {
                    data.attendance == 'leave_without_pay'?(
                      <>
                        <span className="yes" onClick={()=>attendanceAction(data,'leave_without_pay')}>YES</span>
                      </>
                    ):<>
                       <span className="no" onClick={()=>attendanceAction(data,'leave_without_pay')}>NO</span>
                    </>
                  }
                  </TableCell> 
               
              </TableRow>
              ))
            }
            
          

      
        <TableRow>
          <TableCell colSpan={5} style={{fontWeight:'bold',textAlign:'right'}}>Counting Total : </TableCell>
        
          <TableCell style={{fontWeight:'bold',textAlign:'center'}}>
            {
              filterResult.reduce((prev,curr)=>{
                if(curr.attendance == 'precent'){
                  return prev + 1
                }else{
                  return prev + 0
                }
              },0)
            }
          </TableCell>

          <TableCell style={{fontWeight:'bold',textAlign:'center'}}>
            {
              filterResult.reduce((prev,curr)=>{
                if(curr.attendance == 'absence'){
                  return prev + 1
                }else{
                  return prev + 0
                }
              },0)
            }
          </TableCell>

          <TableCell style={{fontWeight:'bold',textAlign:'center'}}>
            {
              filterResult.reduce((prev,curr)=>{
                if(curr.attendance == 'leave_with_pay'){
                  return prev + 1
                }else{
                  return prev + 0
                }
              },0)
            }
          </TableCell>

          <TableCell style={{fontWeight:'bold',textAlign:'center'}}>
            {
              filterResult.reduce((prev,curr)=>{
                if(curr.attendance == 'leave_without_pay'){
                  return prev + 1
                }else{
                  return prev + 0
                }
              },0)
            }
          </TableCell>

        
        
          
         
        </TableRow>
       


        </TableBody>
      </Table>
    </TableContainer>
        </Paper>
  ):''
}
    



{
  ( selectedFilterType != null &&   details.length>0  && (selectedFilterType.filterType == 'By Employee' || selectedFilterType.filterType == 'By Type'))?(
    <Paper className={classes.paper}  style={{paddingLeft:'10px',paddingRight:'10px',marginTop:'1px',marginBottom:'1px'}}>
    
    {/* sale Record  without details */} 
    <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table" className="report-dom">
        <TableHead>
          <TableRow> 
            <TableCell align="left"  width="3%">SL</TableCell>
            <TableCell align="left"  width="5%">VCH No</TableCell>
            <TableCell align="left"  width="10%">Date</TableCell>
            <TableCell align="left"  width="15%">Employee</TableCell>
            <TableCell align="left"  width="10%">Month</TableCell>
            <TableCell align="left"  width="15%">Payment For</TableCell>
            <TableCell align="left"  width="15%">Payment From Account</TableCell>
            <TableCell align="left"  width="5%">Payment By</TableCell>
            <TableCell align="right" width="8%">Total Amount</TableCell> 
          </TableRow>
        </TableHead>
        <TableBody>
            {
              details.map((data,index)=>(
                <TableRow  key={index}> 
                <TableCell  align="left">{index+parseFloat(1)}</TableCell>
                <TableCell align="left">{data.pay_code}</TableCell> 
                <TableCell align="left">{moment(data.creation_date).format(dateFormat)}</TableCell>
                <TableCell align="left">{data.employee_name}</TableCell> 
                <TableCell align="left">{data.month} {data.year}</TableCell> 
                <TableCell align="left">{data.pay_type}</TableCell> 
                <TableCell align="left">{data.acc_name}</TableCell> 
                <TableCell align="left">{data.user_full_name}</TableCell>
                <TableCell align="right">{format(parseFloat(data.pay_amount).toFixed(2))}</TableCell>
               
              </TableRow>
              ))
            }
            
          

      
        <TableRow>
          <TableCell colSpan={8} style={{fontWeight:'bold',textAlign:'right'}}>Grand Total : </TableCell>
        
     
        
          <TableCell  style={{fontWeight:'bold',textAlign:'right'}}>{ format(details.reduce((prev,curr)=>{
                return prev+parseFloat(curr.pay_amount);
          },0).toFixed(2)) }</TableCell>
          
         
        </TableRow>
       


        </TableBody>
      </Table>
    </TableContainer>
        </Paper>
  ):''
}


   {/* with details */}
   {
        (selectedRecordType != null && selectedFilterType != null   && filterResultDetails.length>0 && selectedRecordType.recordType=='with details' && (selectedFilterType.filterType == 'All'  || selectedFilterType.filterType == 'By User' ) && (selectedFilterType.filterType != 'By Employee' && selectedFilterType.filterType != 'By Type'))?(
          <Paper className={classes.paper}  style={{paddingLeft:'10px',paddingRight:'10px',marginTop:'1px',marginBottom:'1px'}} >
    
    <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table" className="report-dom">
        <TableHead>
          <TableRow >
            <TableCell align="left" width="3%">SL</TableCell>
            <TableCell align="left" width="5%">VCH</TableCell>
            <TableCell align="left" width="8%">Date</TableCell>
            <TableCell align="left" width="11%">Payment From Account</TableCell>
            <TableCell align="left" width="11%">Month</TableCell>
            <TableCell align="left" width="10%">Payment By</TableCell>
            <TableCell align="left" width="10%">Employee</TableCell>
            <TableCell align="left" width="10%">Pay For</TableCell>
        
            <TableCell align="right" width="5%"> Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {
              filterResultDetails.map((data,index)=>(
                <>
                <TableRow  key={index}> 
                <TableCell  align="left">{index+parseFloat(1)}</TableCell>
                <TableCell align="left">{data.pay_code}</TableCell> 
                <TableCell align="left">{moment(data.creation_date).format(dateFormat)}</TableCell>
                <TableCell align="left">{data.acc_name}</TableCell>
                <TableCell align="left">{data.month} {data.year}</TableCell>
                <TableCell align="left">{data.user_full_name}</TableCell>
                <TableCell align="left">{data.details[0].acc_name}</TableCell>
                <TableCell align="left">{data.details[0].pay_type}</TableCell>

              
               
                <TableCell align="right">{format(data.details[0].pay_amount)}</TableCell>
          

                
              </TableRow>

              
                   



                    {
                  data.details.slice(1).map((detail,dIndex)=>(
                    <TableRow key={dIndex}> 
                 
                      <TableCell  align="left"  colSpan={6}></TableCell>
                      <TableCell align="left">{detail.acc_name}</TableCell>
                      <TableCell align="left">{detail.pay_type}</TableCell>
                 

       
               
                <TableCell align="right">{format((detail.pay_amount).toFixed(2))}</TableCell>
                    </TableRow>
                  ))

                }


<TableRow> 
                   
                   <TableCell  style={{fontWeight:'bold',textAlign:'right'}} colSpan={8}> Sub Total </TableCell>


              
                   <TableCell  style={{fontWeight:'bold',textAlign:'right'}}> 
                        {format(parseFloat(_.sumBy(data.details,'pay_amount')).toFixed(2))}
                   </TableCell>

                 

                 
                   </TableRow>



                   </>
                
      ))
            }





                {/*  */}

            {
                <TableRow> 
               
              <TableCell  colSpan={8} style={{fontWeight:'bold',textAlign:'right'}}> Grand Total  : </TableCell>


            <TableCell   style={{fontWeight:'bold',textAlign:'right'}}>{format(filterResultDetails.reduce((prev,curr)=>{
                     return prev+parseFloat(curr.pay_total)
            },0).toFixed(2))}
          </TableCell>

           
              </TableRow>
            }
        </TableBody>
      </Table>
    </TableContainer>
        </Paper>
        ):''
      }

      
  
<div style={{clear: 'both',height:'10px'}}></div>
<div  className="print-source" style={{marginTop:'70px'}}>
                                <p style={{float:'left',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Received By</p>
                           
                                 <p style={{float:'right',marginRight:'10px',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Authorized By</p>
                      </div>
          </div>
        )
    })




    const reportRef = useRef();

   

      return(
          <div className={classes.root}> 
<Paper className={classes.paper} style={{marginTop:'-25px',marginBottom:'5px'}}>
<h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-10px',
    marginBottom: '4px'}}>Attendance Entry</h4>
<Grid container spacing={3} > 
          {/* <Grid item  xs={12}  sm={2}> 
          <Autocomplete 
          size="small"
          autoHighlight
              openOnFocus={true}
              style={{width:'100%',height:'20px'}}
              options={filterTypes} 
              value={selectedFilterType}
              getOptionLabel={(option) => option.filterType}
              onChange={(event,selectedObj)=>{
                selectedFilterTypeSet(selectedObj) 
              }}
              renderInput={(params) => <TextField 
               
                {...params} 
                label="Filter Type" 
                variant="outlined"
                />} 
          />

          </Grid> */}

         

         


          <Grid item  xs={12}   sm={2} style={{display: selectedFilterType!=null && (selectedFilterType.filterType=='By Employee' || selectedFilterType.filterType=='By Type')?'block':'none'}}> 
          <Autocomplete 
           autoHighlight
           size="small"
             openOnFocus={true}
             style={{width:'100%',height:'20px'}}
             options={employees}
             loading={loadingEmployees}
             value={selectedEmployee}
             getOptionLabel={(option) => option.employee_name}
             onChange={(event,selectedObj)=>{
                selectedEmployeeSet(selectedObj) 
             }}
             renderInput={(params) => <TextField 
              label="Search Employee" 
               onChange={e => employee_name_set(e.target.value)} 
               {...params} 
               InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loadingEmployees ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
               variant="outlined"
               />} 
          
      />
          </Grid>


       
      <Grid item xs={12} sm={2} style={{float:'left',width: '%',marginTop:'10px'}}>
      
      <Autocomplete
      disabled
        autoHighlight={true}
        openOnFocus={true}
        style={{ width: '%' }}
        options={years} 
        size="small"
        classes={{
          option: classes.option,
        }}
        getOptionLabel={(option) => option.year}
        value={selectedYear}
        onChange={(event,selectedObj)=>{
          selectedYearSet(selectedObj)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Pay of  Year  "
            variant="outlined"
          
          />
        )}
    />

      </Grid>  


      <Grid item xs={12} sm={2} style={{float:'left',marginTop:'10px',marginLeft:'5px'}}>
      
      <Autocomplete
      //  disabled
        autoHighlight={true}
        openOnFocus={true}
        style={{ width: '100%' }}
        options={months} 
        size="small"
        classes={{
          option: classes.option,
        }}
        getOptionLabel={(option) => option.month}
        value={selectedMonth}
        onChange={(event,selectedObj)=>{
          selectedMonthSet(selectedObj)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Pay of  Month  "
            variant="outlined"
          
          />
        )}
    />

      </Grid>  

      <Grid item xs={12} sm={3} style={{float:'right',marginTop: '-8px',marginLeft:'5px'}}> 
      
      <LocalizationProvider dateAdapter={AdapterDateFns} style={{marginTop: '8px'}}>
        <Stack spacing={3}>
          <DesktopDatePicker
          // disabled
            label="Attendance Date" 
            inputFormat={dateTimeFormat}
            value={attendance_date} 
            onChange={(e)=>attendance_date_set(e)}
            renderInput={(params) => <TextField {...params} />}
          />
          
        </Stack>
        </LocalizationProvider>


  </Grid>


          
          <Grid item  xs={12}   sm={1} >
        <Button style={{marginTop: '5px',marginLeft: 'auto',fontSize:'10px'}} 
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            startIcon={<SearchIcon/>}
                            onClick={getSearchResult}
                            disabled={reportLoading?true:false}
                        >
                        Check 
                      </Button>
        </Grid>

        </Grid> 
        
        </Paper>
        {
          print?(
            <Grid container >
            <Grid item xs={12} sm={12} >
              <Paper style={{borderRadius:'0px',marginTop: '-7px'}}>
              <ReactToPrint
                          trigger={() => <PrintIcon  style={{cursor:'pointer',marginLeft: '30px',marginTop: '3px',marginBottom: '-8px'}} />}
                          content={() => reportRef.current}
                        />
              </Paper>
           
            </Grid>
        </Grid>
          ):''
        }
      
            <ReportDom ref={reportRef} /> 




          </div>
      )
}



const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    inputField:{
      width:'100%',
      marginTop:'5px'
    },
    plusLinkDiv:{
      position:'relative'  
    },
    plusLink:{
      margin: 0,
      padding: 0,
      marginTop: '-21px',
      fontSize: '29px',
      height: '21px',
      textAlign: 'right',
      position: 'absolute',
      right: 0,
      color: '#3e8d54'
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));


const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(DebtorReceiptRecord); 
