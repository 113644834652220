import React,{useState,useEffect,useRef} from 'react';
import {connect} from 'react-redux';

import {currentRouteSet,updateCollectionSet} from '../../actions/actions';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid'; 
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import {pathSpliter,_p,sleep,dateTimeFormat,nextTotalDaysOfMonth,dateFormat,currentDateTime,getDateTimeFromISODT,currentDateStartTime,currentDateEndTime,nextTotalWeekOfMonth} from '../../lib/functions'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DdatateIcon from '@material-ui/icons/Delete';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DateFnsUtils from '@date-io/date-fns'; // choose your libs
import {API_URL} from '../../config.json';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import _ from 'lodash';
import ReactToPrint from "react-to-print";
import PrintIcon from '@material-ui/icons/Print';
import InstitutionProfile from '../commons/institution_profile'
import '../commons/commons.css'
import SaveIcon from '@material-ui/icons/Save';

import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';


import swal from 'sweetalert';
import commaNumber from 'comma-number';
import { TableFooter } from 'semantic-ui-react';
let format = commaNumber.bindWith(',', '.')
const SalesRecord = ({location,currentRoute,currentRouteSet,authInfo,updateCollectionSet})=>{
    const classes = useStyles();
    useEffect(()=>{
        currentRouteSet(pathSpliter(location.pathname,1));
        getLocations()
    },[]);

    let [components,componentsSet] = useState([
        {component_name:'everyday'},
        {component_name:'weekly'},
        {component_name:'monthly'},
    ]);
    let [report,reportSet] = useState([]);
    let [selectedComponent,selectedComponentSet] = useState(null);
    let [selectedGroup,selectedGroupSet] = useState(null);
    let [accounts,accountsSet] = useState([]);
    let [groups,groupsSet] = useState([]);
    let [locations,locationsSet] = useState([]);
    
    let [reportLoading,reportLoadingSet] = useState(false);
    

    let [selectedAccount,selectedAccountSet] = useState(null)
    let [selectedLocation,selectedLocationSet] = useState(null)
    let [loadingSave,loadingSaveSet] = useState(false)
  

    

    const [created_date, created_date_set] = useState(currentDateTime);

    
    

    let [print,printSet] = useState(false);
    let [loadingAccounts,loadingAccountsSet] = useState(false);
    let [account_name,account_name_set] = useState('');

    let [balanceTotal,balanceTotalSet] = useState(0)


    let saveAction = async ()=>{

      let total = parseFloat( _.sumBy(report,(acc)=>Number(acc.collected_amount)) )
      if( total == 0 ){
        swal({
          title:'Amount is 0',
          icon:'warning'
        })
        return false
      }else if( selectedGroup == null ){
        swal({
          title:'Select a Group',
          icon:'warning'
        })
        return false
      }else{

        report =  report.filter((cus)=>{
          return parseFloat(cus.collected_amount) != 0 && cus.balance > 0
        })
        
        loadingSaveSet(true)
        await axios.post(`${API_URL}/api/save-customer-collection`,{
          customers : report,
          toDate: created_date,
          into_acc_id: selectedGroup.acc_id,

        },{headers:{'auth-token': authInfo.token}}).then(res=>{
          loadingSaveSet(false);
          getSearchResult()

          swal({
            title:'Saved',
            icon:'success'
          })
          
        });


      }
    }
    
   
    useEffect(()=>{
        selectedAccountSet(null)
        selectedLocationSet(null)
        selectedGroupSet(null)
        getGroups()
    },[selectedComponent])



    useEffect(()=>{
          loadingAccountsSet(true)
          axios.post(`${API_URL}/api/get-accounts-by-search`,{query:account_name.trim(),type:'debitor'},{headers:{'auth-token':authInfo.token}}).then(res=>{
            accountsSet(res.data)
            loadingAccountsSet(false)
          })
          
    },[account_name])


    const getGroups = async ()=>{

      await axios.post(`${API_URL}/api/get-collection-groups`,{componentName : selectedComponent != null ? selectedComponent.component_name : null},{headers:{'auth-token':authInfo.token}}).then(res=>{
        groupsSet(res.data)
      })
    }


    const getLocations = async ()=>{
      await axios.post(`${API_URL}/api/get-locations`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
        locationsSet(res.data)
    })
    }

 

useEffect(()=>{
  if(selectedGroup != null && selectedComponent == null ){
    selectedGroupSet(null)
    swal({
      title:'Select Component',
      icon:'warning'
    })
  }
},[selectedGroup])


useEffect(()=>{
  if(selectedComponent != null){
    getSearchResult()

  }
},[created_date])


useEffect(()=>{
 reportSet([])
},[selectedComponent,selectedGroup])


    
    let getSearchResult = async ()=>{

      if(selectedComponent == null){
        swal({
          title:'Select Component',
          icon:'warning'
        })
        return false
      }

      if(selectedGroup == null){
        swal({
          title:'Select Group',
          icon:'warning'
        })
        return false
      }
     
        let url =  `${API_URL}/api/get-sundry-debitor-balance`;

         


            
        let reqPayload = {
            componentName: selectedComponent != null? selectedComponent.component_name:null,
            groupId: selectedGroup != null? selectedGroup.group_id:null,
            toDate: created_date,

        }

        
        reportLoadingSet(true)
        await axios.post(`${url}`,{...reqPayload},{headers:{'auth-token':authInfo.token}}).then(res=>{
             printSet(true)
             reportLoadingSet(false)


             let componentName = selectedGroup.component_name

             let partialQty = 0

             res.data.accounts = res.data.accounts.filter((acc)=>{
              return acc.group_id > 0 &&  acc.balance > 0
             })


             res.data.accounts = res.data.accounts.map((acc)=>{



              if(componentName == 'monthly'){
                let emi_month = acc.emi_month - acc.paid_day_week_month

               acc.nextCollectionAbleAmount =  acc.balance / emi_month 
              }

              if(componentName == 'everyday'){

                let day_week = acc.day_week - acc.paid_day_week_month
                 
                acc.nextCollectionAbleAmount =  acc.balance / ( day_week ) 
               }

               if(componentName == 'weekly'){
                let day_week = acc.day_week - acc.paid_day_week_month

                acc.nextCollectionAbleAmount =  acc.balance / ( day_week )  
               }

               return acc

             })
            reportSet(res.data.accounts)
            balanceTotalSet(res.data.total_balance)
        })



    }


    let updateCollectionnew = (amount,ind)=>{
      report[ind].collected_amount = amount

      let preCopy =  [...report];
      preCopy[ind] = report[ind];
      reportSet(preCopy)


      console.log(preCopy)


    }
    

    let updateAction = (amount,ind)=>{

     console.log(amount)


    }

//     let ReportDom = React.forwardRef( (props,ref) =>{
//         return(
//           <div ref={ref} >
//                         <InstitutionProfile />

// <div style={{clear:'both'}}></div>
// <Paper style={{paddingLeft:'10px',paddingRight:'10px'}} className="print-source">
// <p style={{width:'100%',textAlign:'center',fontWeight:'bold'}}>Debotor / Customer Balance Report</p>
// <div style={{float:'left',width:'50%',textAlign:'left',margin:0,padding:0,fontSize:'16px'}}>

// </div>

// <div style={{width:'10%'}}></div>

// <div style={{float:'right',width:'40%',textAlign:'left',margin:0,padding:0,fontSize:'16px'}}>

// </div>
 


// </Paper> 

// {
//     report.length > 0 ?(<>
//     <Paper className={classes.paper}  style={{paddingLeft:'10px',paddingRight:'10px',marginTop:'1px',marginBottom:'1px'}}>
    
//     {/* sale Record  without item */} 
//     <TableContainer >
//       <Table className={classes.table} size="small" aria-label="a dense table" className="report-dom">
//         <TableHead>
//           <TableRow> 
//             <TableCell align="left"  width="3%">SL</TableCell>
//             <TableCell align="left"  width="15%">Customer Name </TableCell>
//             <TableCell align="left"  width="10%">Contact No</TableCell>
//             <TableCell align="left"  width="15%">Address</TableCell>
//             <TableCell align="left"  width="5%">Opening </TableCell>
//             <TableCell align="left"  width="7%">Bill Amount</TableCell>
//             <TableCell align="left"  width="7%">Paid </TableCell>
//             <TableCell align="right" width="7%">Current Due</TableCell> 
//             <TableCell align="right" width="7%">Collected Amount</TableCell> 
//           </TableRow>
//         </TableHead>
//         <TableBody>
//                 {
//                     report.map((acc,ind)=>(
//                         <TableRow  key={ind}> 
//                         <TableCell  align="left">{ind+parseFloat(1)}</TableCell>
//                         <TableCell  align="left">{acc.acc_name}</TableCell>
//                         <TableCell  align="left">{acc.contact_no}</TableCell>
//                         <TableCell  align="left">{acc.address}</TableCell>
//                         <TableCell  align="right">{format(parseFloat(acc.opening_balance).toFixed(2))}</TableCell>
//                         <TableCell  align="right">{format(parseFloat(acc.total_bill_amount).toFixed(2))}</TableCell>
//                         <TableCell  align="right">{format(parseFloat(acc.total_received_amount).toFixed(2))}</TableCell>
//                         <TableCell  align="right">{format(parseFloat(acc.balance).toFixed(2))}</TableCell>
//                         <TableCell  align="right"><input type="number" 
//                                             // onBlur={(e) => updateAction(acc)}

// name="collection"
//                         onChange={(e)=>updateCollectionSet(e.target.value,report,ind,acc.updateId,acc.updateType)}  
//                         value={acc.collected_amount}
//                         /> </TableCell>
//                        </TableRow>
//                     ))
//                 }

              

// <TableRow>
//                 <TableCell  style={{fontWeight:'bold'}} align="right" colSpan={4}>Grand Total  : </TableCell>
//                 <TableCell  style={{fontWeight:'bold'}} align="right">{format(parseFloat(_.sumBy(report,(acc)=>Number(acc.opening_balance))).toFixed(2))}</TableCell>
//                 <TableCell  style={{fontWeight:'bold'}} align="right">{format(parseFloat(_.sumBy(report,(acc)=>Number(acc.total_bill_amount))).toFixed(2)) }</TableCell>
//                 <TableCell  style={{fontWeight:'bold'}} align="right">{format(parseFloat(_.sumBy(report,(acc)=>Number(acc.total_received_amount))).toFixed(2))}</TableCell>
//                 <TableCell  style={{fontWeight:'bold'}} align="right"> { format(parseFloat(balanceTotal).toFixed(2))}</TableCell>
//                 <TableCell  style={{fontWeight:'bold'}} align="right">{format(parseFloat(_.sumBy(report,(acc)=>Number(acc.collected_amount))).toFixed(2))} </TableCell>
//                 </TableRow>
//         </TableBody>


 
       
//       </Table>
//     </TableContainer>
//         </Paper>
  
//     </>):''
    
// }
    


      
     
// <div style={{clear: 'both',height:'10px'}}></div>
// <div  className="print-source" style={{marginTop:'70px'}}>
//                                 <p style={{float:'left',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Received By</p>
                           
//                                  <p style={{float:'right',marginRight:'10px',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Authorized By</p>
//                       </div>
//           </div>
//         )
//     })




    const reportRef = useRef();

   

      return(
          <div className={classes.root}> 
<Paper className={classes.paper} style={{marginTop:'-25px',marginBottom:'5px'}}>
<h4 style={{ textAlign: 'left',margin: "0px 0px  7px 0px",padding: '0px',color: '#222'}}>Collection &  Report</h4>
<Grid container spacing={3} > 
          <Grid item  xs={12}  sm={2}> 
          <Autocomplete 
          size="small"
          autoHighlight
              openOnFocus={true}
              style={{width:'100%',height:'20px'}}
              options={components} 
              value={selectedComponent}
              getOptionLabel={(option) => option.component_name}
              onChange={(event,selectedObj)=>{
                selectedComponentSet(selectedObj) 
              }}
              renderInput={(params) => <TextField 
               
                {...params} 
                label="Select Component " 
                variant="outlined"
                />} 
          />

          </Grid>

          


          <Grid item  xs={12}  sm={2}> 
          <Autocomplete 
          size="small"
          autoHighlight
              openOnFocus={true}
              style={{width:'100%',height:'20px'}}
              options={groups} 
              value={selectedGroup}
              getOptionLabel={(option) => option.group_name}
              onChange={(event,selectedObj)=>{
                selectedGroupSet(selectedObj) 
              }}
              renderInput={(params) => <TextField 
               
                {...params} 
                label="Select Group " 
                variant="outlined"
                />} 
          />

          </Grid>


          
        <Grid item xs={12} sm={2} style={{float:'right',marginTop: '',marginLeft:'5px'}}> 
        <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        <DesktopDatePicker
          readOnly={authInfo.role == 'user' ? true : false}
          label=" Date" 
          inputFormat={dateTimeFormat}
          value={created_date}
          onChange={(e)=>created_date_set(e)}
          renderInput={(params) => <TextField {...params} />}
        />
        
      </Stack>
    </LocalizationProvider>
        </Grid>
         


          <Grid item  xs={12}   sm={3} style={{display: selectedComponent!=null && (selectedComponent.type=='Debitor Wise' )?'block':'none'}}> 
          <Autocomplete 
           autoHighlight
           size="small"
             openOnFocus={true}
             style={{width:'100%',height:'20px'}}
             options={accounts}
             loading={loadingAccounts}
             value={selectedAccount}
             getOptionLabel={(option) => option.acc_name}
             onChange={(event,selectedObj)=>{
                selectedAccountSet(selectedObj) 
             }}
             renderInput={(params) => <TextField 
              label="Search Customer/ Debtor " 
               onChange={e => account_name_set(e.target.value)} 
               {...params} 
               InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loadingAccounts ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
               variant="outlined"
               />} 
          
      />
          </Grid>


          <Grid item  xs={12}   sm={3} style={{display: selectedComponent!=null && (selectedComponent.type=='Location Wise')?'block':'none'}}> 
          <Autocomplete 
          size="small"
          autoHighlight
              openOnFocus={true}
              style={{width:'100%',height:'20px'}} 
              options={locations}
              value={selectedLocation}
              getOptionLabel={(option) => option.location_name}
              onChange={(event,selectedObj)=>{
                 selectedLocationSet(selectedObj);
              }}
              renderInput={(params) => <TextField                
                {...params} 
                label="Select Location" 
                variant="outlined"
                />} 
                
          />
          </Grid>

          <Grid item  xs={12}   sm={2} >
        <Button style={{marginTop: '5px',marginLeft: 'auto',fontSize:'10px'}} 
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            startIcon={<SearchIcon/>}
                            onClick={getSearchResult}
                            disabled={reportLoading?true:false}
                        >
                        GET COLLECTION 
                      </Button>
        </Grid>
        


          </Grid>


        </Paper>
        {
          print?(
            <Grid container >
            <Grid item xs={12} sm={12} >
              <Paper style={{borderRadius:'0px',marginTop: '-17px'}}>
              <ReactToPrint
                          trigger={() => <PrintIcon  style={{cursor:'pointer',marginLeft: '30px',marginTop: '3px',marginBottom: '-8px'}} />}
                          content={() => reportRef.current}
                        />
              </Paper>
           
            </Grid>
        </Grid>
          ):''
        }
      


      <div ref={reportRef} >
                        <InstitutionProfile />

<div style={{clear:'both'}}></div>
<Paper style={{paddingLeft:'10px',paddingRight:'10px'}} className="print-source">
<p style={{width:'100%',textAlign:'center',fontWeight:'bold',textTransform:'uppercase'}}> {selectedComponent != null ? selectedComponent.component_name : ''} Collection Report</p>
<div style={{float:'left',width:'50%',textAlign:'left',margin:0,padding:0,fontSize:'16px'}}>

  <p style={{margin:'0px'}}>Employee : {selectedGroup != null ? selectedGroup.employee_name : ''}  </p>
  <p style={{margin:'0px'}}>Component : {selectedComponent != null ? selectedComponent.component_name : ''}  </p>
  <p style={{margin:'0px'}}>Group Name : {selectedGroup != null ? selectedGroup.group_name + " ( " +report.length + " )" : ''}   </p>


  
</div>

<div style={{width:'10%'}}></div>

<div style={{float:'right',width:'40%',textAlign:'left',margin:0,padding:0,fontSize:'16px'}}>
<p style={{margin:'0px'}}>Branch Name: {authInfo.userInfo.branch_name}  </p>

<p style={{margin:'0px'}}>Metting Place : {selectedGroup != null ? selectedGroup.metting_place : ''}  </p>

<p style={{margin:'0px'}}>Date : {moment(created_date).format(dateFormat)}   </p>

</div>
 


</Paper> 

{
    report.length > 0 ?(<>
    <Paper className={classes.paper}  style={{paddingLeft:'10px',paddingRight:'10px',marginTop:'1px',marginBottom:'1px'}}>
    
    {/* sale Record  without item */} 
    <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table" className="report-dom">
        <TableHead>
          <TableRow> 
            <TableCell align="left"  width="3%">SL</TableCell>
            <TableCell align="left"  width="12%">Customer Name </TableCell>
            <TableCell align="left"  width="10%">Contact No</TableCell>
            <TableCell align="left"  width="12%">Address</TableCell>
            <TableCell align="left"  width="5%"> Duration</TableCell>
            <TableCell align="left"  width="5%">Paid Duration</TableCell>
            <TableCell align="left"  width="5%">Opening </TableCell>
            <TableCell align="left"  width="7%">Bill Amount</TableCell>
            <TableCell align="left"  width="7%">Paid </TableCell>
            <TableCell align="right" width="5%">Current Due</TableCell> 
            <TableCell align="right" width="5%">Next Collection  </TableCell> 
            <TableCell align="right" width="10%">Collected Amount</TableCell> 
          </TableRow>
        </TableHead>
        <TableBody>
                {
                    report.map((acc,ind)=>(
                        <TableRow  key={ind}> 
                        <TableCell  align="left">{ind+parseFloat(1)}</TableCell>
                        <TableCell  align="left">{acc.acc_name}</TableCell>
                        <TableCell  align="left">{acc.contact_no}</TableCell>
                        <TableCell  align="left">{acc.address}</TableCell>
                        <TableCell  align="left">{selectedComponent.component_name == 'monthly' ? acc.emi_month :''} {selectedComponent.component_name != 'monthly' ? acc.day_week :''} </TableCell>
                        <TableCell  align="left">{acc.paid_day_week_month}</TableCell>
                        <TableCell  align="right">{format(parseFloat(acc.opening_balance).toFixed(2))}</TableCell>
                        <TableCell  align="right">{format(parseFloat(acc.total_bill_amount).toFixed(2))}</TableCell>
                        <TableCell  align="right">{format(parseFloat(acc.total_received_amount).toFixed(2))}</TableCell>
                        <TableCell  align="right">{format(parseFloat(acc.balance).toFixed(2))}</TableCell>
                        <TableCell  align="right">{format(parseFloat(acc.nextCollectionAbleAmount).toFixed(2))}</TableCell>
                        <TableCell  align="right">
                          

                        <TextField type="number" 
                         autoComplete='off'  className={classes.fullWidth}  value={acc.collected_amount} 
                   label="" name="collected_amount" style={{color:'#222',margin:'2px',textAlign:'center'}} 
                    variant="outlined"
                    // onBlur={(e) => updateLedger(row)}
                    
                    size="small" 

                    // onChange={(e)=>updateCollectionSet(e.target.value,report,ind)}  
                     onChange={(e)=>updateCollectionnew(e.target.value,ind)}  

                    
                    // value={acc.collected_amount}

                   />

                          
                           </TableCell>
                       </TableRow>
                    ))
                }

              

<TableRow>
                <TableCell  style={{fontWeight:'bold'}} align="right" colSpan={5}>
                  Grand Total  :
                 </TableCell>

                 <TableCell  style={{fontWeight:'bold'}} align="right" >

                 <Grid item  xs={12}   sm={12} >
        <Button  className="print-no" style={{marginTop: '5px',marginLeft: 'auto',fontSize:'10px'}} 
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            startIcon={<SaveIcon/>}
                            onClick={saveAction}
                            disabled={loadingSave?true:false}
                        >
                        SAVE 
                      </Button>
        </Grid>


                 </TableCell>
                <TableCell  style={{fontWeight:'bold'}} align="right">{format(parseFloat(_.sumBy(report,(acc)=>Number(acc.opening_balance))).toFixed(2))}</TableCell>
                <TableCell  style={{fontWeight:'bold'}} align="right">{format(parseFloat(_.sumBy(report,(acc)=>Number(acc.total_bill_amount))).toFixed(2)) }</TableCell>
                <TableCell  style={{fontWeight:'bold'}} align="right">{format(parseFloat(_.sumBy(report,(acc)=>Number(acc.total_received_amount))).toFixed(2))}</TableCell>
                <TableCell  style={{fontWeight:'bold'}} align="right"> { format(parseFloat(balanceTotal).toFixed(2))}</TableCell>
                <TableCell  style={{fontWeight:'bold'}} align="right">{format(parseFloat(_.sumBy(report,(acc)=>Number(acc.nextCollectionAbleAmount))).toFixed(2))} </TableCell>
                <TableCell  style={{fontWeight:'bold'}} align="right">{format(parseFloat(_.sumBy(report,(acc)=>Number(acc.collected_amount))).toFixed(2))} </TableCell>
                </TableRow>
        </TableBody>


 
       
      </Table>
    </TableContainer>
        </Paper>
  
    </>):''
    
}
    


      
     
<div style={{clear: 'both',height:'10px'}}></div>
<div  className="print-source" style={{marginTop:'70px'}}>
                                <p style={{float:'left',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Received By</p>
                           
                                 <p style={{float:'right',marginRight:'10px',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Authorized By</p>
                      </div>
          </div>


            {/* <ReportDom ref={reportRef} />  */}




          </div>
      )
}



const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
        color: 'white',
        fontSize:'10px',
        backgroundColor: '#115f5f'
      },
    
    root: {
      flexGrow: 1,
    },
    inputField:{
      width:'100%',
      marginTop:'5px'
    },
    plusLinkDiv:{
      position:'relative'  
    },
    plusLink:{
      margin: 0,
      padding: 0,
      marginTop: '-21px',
      fontSize: '29px',
      height: '21px',
      textAlign: 'right',
      position: 'absolute',
      right: 0,
      color: '#3e8d54'
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));


const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet,updateCollectionSet})(SalesRecord); 
