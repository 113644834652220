import React,{useState,useEffect,useRef} from 'react';
import {connect} from 'react-redux';

import { Modal } from 'react-responsive-modal';
import "react-responsive-modal/styles.css";
import {currentRouteSet} from '../../actions/actions';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid'; 
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import {pathSpliter,_p,sleep,dateTimeFormat,dateFormat,currentDateTime,getDateTimeFromISODT,currentDateStartTime,currentDateEndTime} from '../../lib/functions'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DdatateIcon from '@material-ui/icons/Delete';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DateFnsUtils from '@date-io/date-fns'; // choose your libs
import {API_URL} from '../../config.json';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import _ from 'lodash';
import ReactToPrint from "react-to-print";
import PrintIcon from '@material-ui/icons/Print';
import InstitutionProfile from '../commons/institution_profile'
import '../commons/commons.css'

import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';


import swal from 'sweetalert';
import commaNumber from 'comma-number';
import { TableFooter } from 'semantic-ui-react';
let format = commaNumber.bindWith(',', '.')
const SalesRecord = ({location,currentRoute,currentRouteSet,authInfo})=>{
    const classes = useStyles();
    useEffect(()=>{
        currentRouteSet(pathSpliter(location.pathname,1));
        getGroups()
        getCategories()

        is_warehouse_set(authInfo.is_warehouse)
        is_serial_set(authInfo.is_serial)
        if(authInfo.is_warehouse == 'yes'){
          getWarehouses()
          let preCopy =  [...types];
          preCopy.push({type:'Warehouse Wise'})
          typesSet(preCopy)
        }
    },[]);

    let [types,typesSet] = useState([{type:'All Item Stock'},{type:'Item Wise'},{type:'Group Wise'},{type:'Category Wise'}]);
    let [report,reportSet] = useState([]);
    let [selectedType,selectedTypeSet] = useState({type:'All Item Stock'});
    let [items,itemsSet] = useState([]);
    let [categories,categoriesSet] = useState([]);
    let [groups,groupsSet] = useState([]);
    
    let [is_serial,is_serial_set] = useState('');
    let [is_warehouse,is_warehouse_set] = useState('');
    

    let [selectedItem,selectedItemSet] = useState(null)
    let [selectedGroup,selectedGroupSet] = useState(null)
    let [selectedCategory,selectedCategorySet] = useState(null)
    let [selectedSerialItem,selectedSerialItemSet] = useState(null)

    

    let [serialModal,serialModalSet] = useState(false)
    let [serials,serialsSet] = useState([])
    let [warehouses,warehousesSet] = useState([])
    let [selectedWarehouse,selectedWarehouseSet] = useState(null)
    
    let [serialsLoading,serialsLoadingSet] = useState(false)
    let [reportLoading,reportLoadingSet] = useState(false)

    
  

    

    
    

    let [print,printSet] = useState(false);
    let [loadingitems,loadingitemsSet] = useState(false);
    let [item_name,item_name_set] = useState('');

    let [stockValueTotal,stockValueTotalSet] = useState(0)
    
   
    useEffect(()=>{
        selectedItemSet(null)
        selectedGroupSet(null)
        selectedCategorySet(null)
        selectedWarehouseSet(null)
    },[selectedType])

    const getGroups = async ()=>{
        await axios.post(`${API_URL}/api/get-item-groups`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
          groupsSet(res.data)
        })
      }

      const getCategories = async ()=>{
        await axios.post(`${API_URL}/api/get-item-categories`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
          categoriesSet(res.data)
        })
      }



    useEffect(()=>{
          loadingitemsSet(true)
          axios.post(`${API_URL}/api/get-items-by-search`,{query:item_name.trim()},{headers:{'auth-token':authInfo.token}}).then(res=>{
            itemsSet(res.data)
            loadingitemsSet(false)
          })
          
    },[item_name])

    
    let getSearchResult = async ()=>{

      if(selectedType == null){
        swal({
          title:'Select Filter Type',
          icon:'warning'
        })
        return false
      }
     
        let url =  `${API_URL}/api/get-stock-report`;

         
            
        let reqPayload = {
            itemId: selectedItem != null? selectedItem.item_id:null,
            groupId: selectedGroup != null? selectedGroup.group_id:null,
            categoryId: selectedCategory != null? selectedCategory.category_id:null,
            warehouseId: selectedWarehouse != null? selectedWarehouse.warehouse_id:null,
        }

        
        reportLoadingSet(true)
        await axios.post(`${url}`,{...reqPayload},{headers:{'auth-token':authInfo.token}}).then(res=>{
             printSet(true)
             reportLoadingSet(false)
            reportSet(res.data)
        })



    }

    let getSerials = async(item)=>{
      serialsSet([])
      serialsLoadingSet(true)
     await axios.post(`${API_URL}/api/get-serials`,{itemId : item.item_id,warehouseId : item.warehouse_id==undefined?0:item.warehouse_id},{headers:{'auth-token':authInfo.token}}).then(res=>{
        serialsSet(res.data)
        serialsLoadingSet(false)
      })
    }

    let getWarehouses = async()=>{
     await axios.post(`${API_URL}/api/get-warehouses`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
        warehousesSet(res.data.message)
      })
    }




    let ReportDom = React.forwardRef( (props,ref) =>{
        return(
          <div ref={ref} >
                        <InstitutionProfile />

<div style={{clear:'both'}}></div>
<Paper style={{paddingLeft:'10px',paddingRight:'10px'}} className="print-source">
<p style={{width:'100%',textAlign:'center',fontWeight:'bold'}}>Item Stock Report</p>
<div style={{float:'left',width:'50%',textAlign:'left',margin:0,padding:0,fontSize:'16px'}}>

</div>

<div style={{width:'10%'}}></div>

<div style={{float:'right',width:'40%',textAlign:'left',margin:0,padding:0,fontSize:'16px'}}>
<p style={{margin:'0px'}}>Statement  Date :  { moment().format(dateFormat)  }</p>

</div>
 


</Paper> 

{
    report.length > 0 ?(<>
    <Paper className={classes.paper}  style={{paddingLeft:'10px',paddingRight:'10px',marginTop:'1px',marginBottom:'1px'}}>
    
    {/* sale Record  without item */} 
    <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table" className="report-dom">
       
        <TableBody>

        <TableRow style={{background: '#cecfce'}}> 
            <TableCell align="left"  width="3%" style={{fontWeight: 'bold',fontSize: '5px',}}>SL</TableCell>
            <TableCell align="left"  width="5%" style={{fontWeight: 'bold',fontSize: '4px',}}>Code</TableCell>
            <TableCell align="left"  width="12%" style={{fontWeight: 'bold',fontSize: '15px',}}>Item Name</TableCell>
            {
              is_warehouse == 'yes'?(<>
                  <TableCell align="left"  width="15%" style={{fontWeight: 'bold',fontSize: '15px',}}>Warehouse</TableCell>
              </>):''
            }
            <TableCell align="left"  width="5%" style={{fontWeight: 'bold',fontSize: '10px',}}>Group</TableCell>
            <TableCell align="left"  width="5%" style={{fontWeight: 'bold',fontSize: '10px',}}>Category</TableCell>
            {
              is_serial == 'yes'?(<>
                  <TableCell align="left"  width="1%" style={{fontWeight: 'bold',fontSize: '12px',}}>Serials</TableCell>
              </>):''
            }
            <TableCell align="right" width="5%" style={{fontWeight: 'bold',fontSize: '10px',}}>Opening Stock </TableCell>
            <TableCell align="right" width="5%" style={{fontWeight: 'bold',fontSize: '10px',}}>Purchased </TableCell>
            <TableCell align="right" width="5%" style={{fontWeight: 'bold',fontSize: '5px',}}>Production  </TableCell>
            <TableCell align="right" width="5%" style={{fontWeight: 'bold',fontSize: '10px',}}>Sold </TableCell> 
            <TableCell align="right" width="5%" style={{fontWeight: 'bold',fontSize: '5px',}}>Consume </TableCell> 
            <TableCell align="right" width="5%" style={{fontWeight: 'bold',fontSize: '5px',}}>Purchase Return </TableCell> 
            <TableCell align="right" width="5%" style={{fontWeight: 'bold',fontSize: '5px',}}>Sales Return </TableCell> 
            <TableCell align="right" width="5%" style={{fontWeight: 'bold',fontSize: '5px',}}>Transfer </TableCell> 
            <TableCell align="right" width="5%" style={{fontWeight: 'bold',fontSize: '5px',}}>Received </TableCell> 
            <TableCell align="right" width="5%" style={{fontWeight: 'bold',fontSize: '5px',}}>Damage </TableCell> 
            <TableCell align="right" width="5%" style={{fontWeight: 'bold',fontSize: '5px',}}>Replace Return </TableCell> 
            <TableCell align="right" width="5%" style={{fontWeight: 'bold',fontSize: '5px',}}>Replace Given </TableCell> 
            <TableCell align="right" width="5%" style={{fontWeight: 'bold',fontSize: '12px',}}>Costing Rate(AVG.)</TableCell> 
            <TableCell align="right" width="7%" style={{fontWeight: 'bold',fontSize: '20px',}}>Current Stock</TableCell> 
            <TableCell align="right" width="7%" style={{fontWeight: 'bold',fontSize: '12px',}}>Stock Value</TableCell> 
          </TableRow>
                {
                    report.map((item,ind)=>(
                        <TableRow  key={ind} style={{background: item.current_qty < 0 ? 'red':'' }}> 
                        <TableCell  align="left">{ind+parseFloat(1)}</TableCell>
                        <TableCell  align="left">{item.item_code}</TableCell>
                        <TableCell  align="left">{item.item_name}</TableCell>
                        {
                          is_warehouse == 'yes'?(<>
                         <TableCell  align="left">{item.warehouse_name}</TableCell>
                          </>):''
                        }
                        <TableCell  align="left">{item.group_name}</TableCell>
                        <TableCell  align="left">{item.category_name}</TableCell> 
                        {
                          is_serial == 'yes'?(<>
                            <TableCell  align="left" >
                        {
                          item.is_serial == 'yes' ? (<>
                            <RemoveRedEyeIcon style={{cursor:'pointer'}} onClick={()=>{
                              selectedSerialItemSet(item)
                              serialModalSet(true)
                              getSerials(item)
                              }} />
                          </>):''
                        }
                        </TableCell>
                          </>):""
                        }
                          <TableCell  align="right">{
                            item.conversion == 1 ? item.opening_qty +` ${item.unit_symbol}` :Math.trunc(parseFloat(item.opening_qty / item.conversion)).toFixed(0) +` ${item.unit_symbol}, ` + (item.opening_qty  % item.conversion) +' ' + item.base_unit_name 
                        }</TableCell>
                        <TableCell  align="right">{
                            item.conversion == 1 ? item.purchase_qty +` ${item.unit_symbol}` :Math.trunc(parseFloat(item.purchase_qty / item.conversion)).toFixed(0) +` ${item.unit_symbol}, ` + (item.purchase_qty  % item.conversion) +' ' + item.base_unit_name 
                        }</TableCell>
                        <TableCell  align="right">{
                         
                            item.conversion == 1 ?   parseFloat( item.production_qty).toFixed(2) +` ${item.unit_symbol}` :Math.trunc(parseFloat(item.production_qty / item.conversion)).toFixed(0) +` ${item.unit_symbol}, ` + (item.production_qty  % item.conversion) +' ' + item.base_unit_name 
                         
                        }</TableCell>
                        <TableCell  align="right">{

                        item.conversion == 1 ?  parseFloat(item.sale_qty).toFixed(2) +` ${item.unit_symbol}` :Math.trunc(parseFloat(item.sale_qty / item.conversion)).toFixed(0) +` ${item.unit_symbol}, ` + (item.sale_qty  % item.conversion) +' ' + item.base_unit_name 
                        }</TableCell>
                        <TableCell  align="right">{
                        item.conversion == 1 ? parseFloat(item.consume_qty).toFixed(2)  +` ${item.unit_symbol}` :Math.trunc(parseFloat(item.consume_qty / item.conversion)).toFixed(0) +` ${item.unit_symbol}, ` + (item.consume_qty  % item.conversion) +' ' + item.base_unit_name 
                        }</TableCell>
                        <TableCell  align="right">{
                        item.conversion == 1 ? item.purchase_return_qty +` ${item.unit_symbol}` :Math.trunc(parseFloat(item.purchase_return_qty / item.conversion)).toFixed(0) +` ${item.unit_symbol}, ` + (item.purchase_return_qty  % item.conversion) +' ' + item.base_unit_name 
                        }</TableCell>
                        <TableCell  align="right">{
                        item.conversion == 1 ? item.sale_return_qty +` ${item.unit_symbol}` :Math.trunc(parseFloat(item.sale_return_qty / item.conversion)).toFixed(0) +` ${item.unit_symbol}, ` + (item.sale_return_qty  % item.conversion) +' ' + item.base_unit_name 
                        }</TableCell>


                       <TableCell  align="right">{
                        item.conversion == 1 ? item.transfer_out_qty +` ${item.unit_symbol}` :Math.trunc(parseFloat(item.transfer_out_qty / item.conversion)).toFixed(0) +` ${item.unit_symbol}, ` + (item.transfer_out_qty  % item.conversion) +' ' + item.base_unit_name 
                        }</TableCell>

                       <TableCell  align="right">{
                        item.conversion == 1 ? item.transfer_in_qty +` ${item.unit_symbol}` :Math.trunc(parseFloat(item.transfer_in_qty / item.conversion)).toFixed(0) +` ${item.unit_symbol}, ` + (item.transfer_in_qty  % item.conversion) +' ' + item.base_unit_name 
                        }</TableCell>


<TableCell  align="right">{
                        item.conversion == 1 ? item.damage_qty +` ${item.unit_symbol}` :Math.trunc(parseFloat(item.damage_qty / item.conversion)).toFixed(0) +` ${item.unit_symbol}, ` + (item.transfer_in_qty  % item.conversion) +' ' + item.base_unit_name 
                        }</TableCell>


<TableCell  align="right">{
                        item.conversion == 1 ? item.replace_return_qty +` ${item.unit_symbol}` :Math.trunc(parseFloat(item.replace_return_qty / item.conversion)).toFixed(0) +` ${item.unit_symbol}, ` + (item.transfer_in_qty  % item.conversion) +' ' + item.base_unit_name 
                        }</TableCell>

<TableCell  align="right">{
                        item.conversion == 1 ? item.replace_given_qty +` ${item.unit_symbol}` :Math.trunc(parseFloat(item.replace_given_qty / item.conversion)).toFixed(0) +` ${item.unit_symbol}, ` + (item.transfer_in_qty  % item.conversion) +' ' + item.base_unit_name 
                        }</TableCell>
                       
<TableCell  align="right">

                        {
                          authInfo.role == 'super_admin' ?(<>
                        {format(parseFloat(item.average_rate).toFixed(2))} <br/>
                        {
                         `  PER ` + (item.conversion == 1 ? `${item.unit_symbol}` :  item.base_unit_name )
                        }
                          </>):''
                        }

</TableCell>
                        <TableCell  align="right" style={{fontWeight: 'bold',fontSize: '12px',}}>{item.display_qty}</TableCell>

                        <TableCell  align="right">

                        {
                          authInfo.role == 'super_admin' ?(<>
                          
                          {format(parseFloat(item.stock_value).toFixed(2))}
                          
                          </>):''
                        }
                        </TableCell>
                       </TableRow>
                    ))
                }

              
               <TableRow >
               <TableCell style={{fontWeight:'bold'}} align="right" colSpan={is_serial == 'yes' && is_warehouse != 'yes' ?6: is_serial == 'yes' && is_warehouse == 'yes' ? 7 :  is_warehouse == 'yes' ? 6 : 5}>Grand Total : </TableCell>

                <TableCell style={{fontWeight:'bold'}} align="right" >{ format(parseFloat(_.sumBy(report,(item)=>Number(item.opening_qty))).toFixed(2)) }</TableCell>
                <TableCell style={{fontWeight:'bold'}} align="right" >{ format(parseFloat(_.sumBy(report,(item)=>Number(item.purchase_qty))).toFixed(2)) }</TableCell>
                <TableCell style={{fontWeight:'bold'}} align="right" >{ format(parseFloat(_.sumBy(report,(item)=>Number(item.production_qty))).toFixed(2)) }</TableCell>
                <TableCell style={{fontWeight:'bold'}} align="right" >{ format(parseFloat(_.sumBy(report,(item)=>Number(item.sale_qty))).toFixed(2)) }</TableCell>
                <TableCell style={{fontWeight:'bold'}} align="right" >{ format(parseFloat(_.sumBy(report,(item)=>Number(item.consume_qty))).toFixed(2)) }</TableCell>
                <TableCell style={{fontWeight:'bold'}} align="right" >{ format(parseFloat(_.sumBy(report,(item)=>Number(item.purchase_return_qty))).toFixed(2)) }</TableCell>
                <TableCell style={{fontWeight:'bold'}} align="right" >{ format(parseFloat(_.sumBy(report,(item)=>Number(item.sale_return_qty))).toFixed(2)) }</TableCell>
                <TableCell style={{fontWeight:'bold'}} align="right" >{ format(parseFloat(_.sumBy(report,(item)=>Number(item.transfer_out_qty))).toFixed(2)) }</TableCell>
                <TableCell style={{fontWeight:'bold'}} align="right" >{ format(parseFloat(_.sumBy(report,(item)=>Number(item.transfer_in_qty))).toFixed(2)) }</TableCell>
                <TableCell style={{fontWeight:'bold'}} align="right" >{ format(parseFloat(_.sumBy(report,(item)=>Number(item.damage_qty))).toFixed(2)) }</TableCell>
                <TableCell style={{fontWeight:'bold'}} align="right" >{ format(parseFloat(_.sumBy(report,(item)=>Number(item.replace_return_qty))).toFixed(2)) }</TableCell>
                <TableCell style={{fontWeight:'bold'}} align="right" >{ format(parseFloat(_.sumBy(report,(item)=>Number(item.replace_given_qty))).toFixed(2)) }</TableCell>
                <TableCell style={{fontWeight:'bold'}} align="right" ></TableCell>
                <TableCell style={{fontWeight:'bold'}} align="right" >{ format(parseFloat(_.sumBy(report,(item)=>Number(item.current_qty))).toFixed(2)) }</TableCell>

               
                <TableCell style={{fontWeight:'bold'}} align="right">

                  {

authInfo.role == 'super_admin' ?(<>
                  {format(parseFloat(_.sumBy(report,(item)=>Number(item.stock_value))).toFixed(2))}


</>):''

                  }
                  
                  
                  </TableCell>
                </TableRow>

        </TableBody>
 
        
      </Table>
    </TableContainer>
        </Paper>
  
    </>):''
    
}
    


      
     
<div style={{clear: 'both',height:'10px'}}></div>
<div  className="print-source" style={{marginTop:'70px'}}>
                                <p style={{float:'left',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Received By</p>
                           
                                 <p style={{float:'right',marginRight:'10px',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Authorized By</p>
                      </div>
          </div>
        )
    })




    const reportRef = useRef();

   

      return(
          <div className={classes.root}> 



              {/* Serial  Modal */}
   <Modal
        open={serialModal}
        onClose={() => serialModalSet(false)}
        center
        style={{minWidth:'600px !important',minHeight:'500px'}}
 
      
      >
        <h2 style={{margin: 0,
    padding: 0,
    color: '#0f7e77'}}>Available ! Serials  of <p style={{fontSize:'14px',margin: 0,
    padding: 0,color:'green'}}>{selectedSerialItem != null ? selectedSerialItem.item_name :''}</p></h2>
     

        <br/>
          <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Counting</TableCell>
            <TableCell align="right">Serial</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

        
        
        {
            serials.map((serial,ind)=>(
              <TableRow>
              <TableCell align="left">{ind+parseFloat(1)}</TableCell>
              <TableCell align="right">{serial.serial_number}</TableCell>
            </TableRow>
            ))
          }

          {
            serialsLoading == true ? (<>
            <TableRow>
            <TableCell align="left" colSpan={2} style={{color:'red'}}>Loading.....</TableCell>
          </TableRow>
            </>):''
          }
        



        </TableBody>
      </Table>
    </TableContainer>



      </Modal>





      {/*Modal End */}
<Paper className={classes.paper} style={{marginTop:'-25px',marginBottom:'5px'}}>
<h4 style={{ textAlign: 'left',margin: "0px 0px  7px 0px",padding: '0px',color: '#222'}}>Item Stock Report</h4>
<Grid container spacing={3} > 
          <Grid item  xs={12}  sm={2}> 
          <Autocomplete 
          size="small"
          autoHighlight
              openOnFocus={true}
              style={{width:'100%',height:'20px'}}
              options={types} 
              value={selectedType}
              getOptionLabel={(option) => option.type}
              onChange={(event,selectedObj)=>{
                selectedTypeSet(selectedObj) 
              }}
              renderInput={(params) => <TextField 
               
                {...params} 
                label="Filter Type" 
                variant="outlined"
                />} 
          />

          </Grid>

          
         


          <Grid item  xs={12}   sm={3} style={{display: selectedType!=null && (selectedType.type=='Item Wise' )?'block':'none'}}> 
          <Autocomplete 
           autoHighlight
           size="small"
             openOnFocus={true}
             style={{width:'100%',height:'20px'}}
             options={items}
             loading={loadingitems}
             value={selectedItem}
             getOptionLabel={(option) => option.display_text}
             onChange={(event,selectedObj)=>{
                selectedItemSet(selectedObj) 
             }}
             renderInput={(params) => <TextField 
              label="Search Item " 
               onChange={e => item_name_set(e.target.value)} 
               {...params} 
               InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loadingitems ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
               variant="outlined"
               />} 
          
      />
          </Grid>


          <Grid item xs={12} sm={3} style={{display: selectedType!=null && (selectedType.type=='Group Wise' )?'block':'none'}} > 

                <Autocomplete
                autoHighlight={true}
                openOnFocus={true}
                style={{ width: '100%' }}
                options={groups} 
                size="small"
                classes={{
                option: classes.option,
                }}
                getOptionLabel={(option) => option.group_name}
                value={selectedGroup}
                onChange={(event,selectedObj)=>{
                    selectedGroupSet(selectedObj)
                }}
                renderInput={(params) => (
                <TextField
               
                    {...params}
                    label="Choose a  Group"
                    variant="outlined"
                
                />
                )}
                />
                </Grid>

          <Grid item xs={12} sm={3} style={{display: selectedType!=null && (selectedType.type=='Category Wise' )?'block':'none'}} > 

              <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={categories} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.category_name}
              value={selectedCategory}
              onChange={(event,selectedObj)=>{
                  selectedCategorySet(selectedObj)
              }}
              renderInput={(params) => (
                <TextField
               
                  {...params}
                  label="Choose a  Category"
                  variant="outlined"
                
                />
              )}
          />
        </Grid>


        <Grid item xs={12} sm={3} style={{display: selectedType!=null && (selectedType.type=='Warehouse Wise' )?'block':'none'}} > 
            
          <Autocomplete
          autoHighlight={true}
          openOnFocus={true}
          style={{ width: '100%' }}
          options={warehouses} 
          size="small"
          classes={{
            option: classes.option,
          }}
          getOptionLabel={(option) => option.warehouse_name}
          value={selectedWarehouse}
          onChange={(event,selectedObj)=>{
              selectedWarehouseSet(selectedObj)
          }}
          renderInput={(params) => (
            <TextField
          
              {...params}
              label="Choose a  Warehouse"
              variant="outlined"
            
            />
          )}
          />
          </Grid>




          <Grid item  xs={12}   sm={2} >
        <Button style={{marginTop: '5px',marginLeft: 'auto',fontSize:'10px'}} 
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            startIcon={<SearchIcon/>}
                            onClick={getSearchResult}
                            disabled={reportLoading?true:false}
                        >
                        GET REPORT 
                      </Button>
        </Grid>
        


          </Grid>


        </Paper>
        {
          print?(
            <Grid container >
            <Grid item xs={12} sm={12} >
              <Paper style={{borderRadius:'0px',marginTop: '-17px'}}>
              <ReactToPrint
                          trigger={() => <PrintIcon  style={{cursor:'pointer',marginLeft: '30px',marginTop: '3px',marginBottom: '-8px'}} />}
                          content={() => reportRef.current}
                        />
              </Paper>
           
            </Grid>
        </Grid>
          ):''
        }
      
            <ReportDom ref={reportRef} /> 







          </div>
      )
}



const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
        color: 'white',
        fontSize:'10px',
        backgroundColor: '#115f5f'
      },
    
    root: {
      flexGrow: 1,
    },
    inputField:{
      width:'100%',
      marginTop:'5px'
    },
    plusLinkDiv:{
      position:'relative'  
    },
    plusLink:{
      margin: 0,
      padding: 0,
      marginTop: '-21px',
      fontSize: '29px',
      height: '21px',
      textAlign: 'right',
      position: 'absolute',
      right: 0,
      color: '#3e8d54'
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));


const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(SalesRecord); 
