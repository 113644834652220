
const currentRouteSet = (current)=>{
      return {
          type:'current_route',
          payload:current
      }
}
const authInfoSet = (authInfo)=>{
    return {
        type:'auh_info_set',
        payload:authInfo
    }
}

// Branch set 
const createdBranchSet = (branch)=>{
    return {
        type:'created_branch_set',
        payload:branch
    }
}
const updatedBranchSet = (branch)=>{
    return {
        type:'updated_branch_set',
        payload:branch
    }
}
const disableRestoreBranchSet = (branch)=>{
    return {
        type:'disable_restore_branch_set',
        payload:branch
    }
}

//  Branch End
// Warehouse function 
const createdWarehouseSet = (warehouse)=>{
    return {
        type:'created_warehouse_set',
        payload:warehouse
    }
}
const updatedWarehouseSet = (warehouse)=>{
    return {
        type:'updated_warehouse_set',
        payload:warehouse
    }
}
const disableRestoreWarehouseSet = (warehouse)=>{
    return {
        type:'disable_restore_warehouse_set',
        payload:warehouse
    }
}
// Warehouse end 


const updateCollectionSet = (amount,arr,ind)=>{
    arr[ind].collected_amount = amount

         console.log(arr[ind].collected_amount)

    return {
        type:'collection',
        payload:[...arr,arr[ind]]
    }
}


export {currentRouteSet,authInfoSet,
    createdWarehouseSet,updatedWarehouseSet,disableRestoreWarehouseSet,
    createdBranchSet,updatedBranchSet,disableRestoreBranchSet,updateCollectionSet
}