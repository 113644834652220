import React,{ useState,useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {connect} from 'react-redux';
import swal from 'sweetalert';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MUIDataTable from "mui-datatables";
import {API_URL} from '../../config.json';
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import axios from 'axios';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import AsyncSelect from 'react-select/async';

import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DateFnsUtils from '@date-io/date-fns';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';

import DeleteIcon from '@material-ui/icons/Delete';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';



import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';




import {currentRouteSet} from '../../actions/actions';

import {pathSpliter,checkAuthBranchWare,dateTimeFormat,currentDateTime,dateFormat} from '../../lib/functions'
import moment from 'moment';


import _ from 'lodash';
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')

const GroupManage = ({location,currentRouteSet,currentRoute,authInfo})=>{
    const classes = useStyles();
 
  useEffect(()=>{
    getIncomes();
    currentRouteSet(pathSpliter(location.pathname,1));
    getExpCode();
    getLoadToAccounts()
  },[])

    // Init states start
 
    
    let [income_id,income_id_set] = useState(0)
    let [incomes,incomesSet] = useState([])
    let [incomeCart,incomeCartSet] = useState([])
    let [loadFromAccounts,loadFromAccountsSet] = useState([])
    let [loadToAccounts,loadToAccountsSet] = useState([])
    
    let [loadingList,loadingListSet] = useState(false)
    let [loadingSave,loadingSaveSet] = useState(false)

    let [action,action_set] = useState('create');
    let [income_code,income_code_set] = useState('');
    let [income_ind_id,income_ind_id_set] = useState(null);

    let [narration,narration_set] = useState('');

    

    let [inc_amount,inc_amount_set] = useState('');
    
    
    const [incomeDate, incomeDateSet] = useState(currentDateTime);
    const [selectedAccountFrom, selectedAccountFromSet] = useState(null);
    const [selectedAccountInto, selectedAccountIntoSet] = useState(null);

    let code_ref = useRef(null)
    let acc_from_ref = useRef(null)
    let acc_into_ref = useRef(null)
    let inc_amount_ref = useRef(null)
    let income_date_ref = useRef(null)
    let narration_ref = useRef(null)


  
  useEffect(()=>{
    getIncomes()
  },[incomeDate])

   
    const incomeDateChange = (date) => {
        incomeDateSet(date);
      };


    
    const getIncomes = async ()=>{
        await axios.post(`${API_URL}/api/get-income-with-details`,{oneDate:incomeDate},{headers:{'auth-token':authInfo.token}}).then(res=>{
        incomesSet(res.data)
      })
    }


    
    const [account_name_from, account_name_from_set] = useState("");
    const [account_name_to, account_name_to_set] = useState("");

    useEffect(()=>{
      getLoadFromAccounts()
    },[account_name_from]);

    // useEffect(()=>{
    //   getLoadToAccounts()
    // },[account_name_to]);

    const getLoadFromAccounts = async() => {
      return  await axios.post(`${API_URL}/api/get-accounts-by-search`,{
        // query:account_name_from,search:true,
        type:'indirect_income'},{headers:{'auth-token':authInfo.token}}).then(res=>{
        loadFromAccountsSet(res.data)
        })
    };

    const getLoadToAccounts = async() => {
      return  await axios.post(`${API_URL}/api/get-accounts-by-search`,{
        // query:account_name_to,search:true,
        multiType:['cash_in_hand','bank_account']},{headers:{'auth-token':authInfo.token}}).then(res=>{
        loadToAccountsSet(res.data)
        })
    };


    const getExpCode = async() => {
      return  await axios.post(`${API_URL}/api/get-income-code`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
          income_code_set(res.data)
        })
    };
  
  



    // Init states end
    // Methods script start
    const saveFormAction = async ()=>{

            let totalIncome = _.sumBy(incomeCart,(tran)=>parseFloat(tran.inc_amount))

            if(incomeCart.length == 0){
              swal({
                title:`Income Cart is Empty.`,
                icon:'warning'
              });
              return false
            }else if(selectedAccountInto == null){
                swal({
                  title:`Select Receipt Into  Account`,
                  icon:'warning'
                });
                return false
              }else{
            let payLoad = {
              income:{
                inc_code:income_code.trim(),
                creation_date:incomeDate,
                into_acc_id: selectedAccountInto.acc_id,
                inc_total:totalIncome,
                narration,
                inc_id:income_id,
                action
              },
              incomeDetail:incomeCart
            }
            loadingSaveSet(true);
            await axios.post(`${API_URL}/api/save-income`,{  
              ...payLoad
            },{headers:{'auth-token': authInfo.token}}).then(res=>{
              loadingSaveSet(false);
             if(res.data.error == false){
               swal({
                 title: res.data.msg,
                 icon:'success'
               })
               incomeDateSet(currentDateTime)
               incomeCartSet([])
               action_set('create')
               narration_set('')
               income_id_set(0)
               getExpCode()
               getIncomes()
               selectedAccountIntoSet(null)

             }else{
              swal({
                title: res.data.msg,
                icon:'warning'
              })
             }
            });

            }
         
            
          
          
    }


  const removeIncomeTran = (sl)=>{
    let preCopy =  [...incomeCart];
    preCopy.splice(sl, 1);
    incomeCartSet(preCopy)

  }

  const addTOIncome = ()=>{

    if(selectedAccountFrom == null){
      swal({
        title:`Select Income Head `,
        icon:'warning'
      });
      return false
    }else if((inc_amount == 0 || inc_amount == '')){
      swal({
        title:`Income Amount  cannot be 0.`,
        icon:'warning'
      });
      return false
    }else{

      let tran = {
        from_acc_id : selectedAccountFrom.acc_id,
        acc_name : selectedAccountFrom.acc_name,
        inc_amount : inc_amount =='' ? 0 :inc_amount
      }
      if(income_ind_id == null){
        incomeCartSet([...incomeCart,tran])
      }else{
        let preCopy =  [...incomeCart];
        preCopy[income_ind_id] = tran
        incomeCartSet(preCopy)

      }


      income_ind_id_set(null)
      selectedAccountFromSet(null)

      inc_amount_set('')
      
     }

    
     

   
    }

    // Methods script end
    const ActionOptions = (props)=>{
      return(<div style={{textAlign:'right'}}> 

{
    authInfo.role !='user'?(
    <>
        <EditIcon style={{cursor:'pointer',fontSize: '28px',color: 'rgb(15, 126, 119)'}} onClick={()=>incomeEdit(props.rowData)}/>
      
        <DeleteIcon style={{cursor:'pointer',color: '#ff0202',fontSize: '28px'}} onClick={()=>incomeDelete(props.rowData)}/>
            
   </>):''
 }
      
      </div>)
    
    }


    const incomeEdit = (row,index)=>{
      let income =  incomes.filter((income)=>income.inc_id == row[0])
      income = income[0]
      incomeCartSet(income.details)
      selectedAccountIntoSet({acc_name:income.acc_name,acc_id:income.into_acc_id})

      income_id_set(income.inc_id)
      action_set('update')
      income_code_set(income.inc_code)
      incomeDateSet(income.creation_date)
      narration_set(income.narration)
     
    }


    const editIncomeTran = (row,sl)=>{
      income_ind_id_set(sl)
      selectedAccountFromSet({acc_name:row.acc_name,acc_id:row.acc_id})
      inc_amount_set(row.inc_amount)
    }


    const incomeDelete = async (row)=>{
      swal({
        title:'Are you sure delete this?',
        icon:'warning',
        buttons:true
      }).then(async(yes)=>{
        if(yes){
          let income =  incomes.filter((income)=>income.inc_id == row[0])
          await axios.post(`${API_URL}/api/delete-income`,{inc_id:income[0].inc_id},
          {headers:{'auth-token':authInfo.token}}).then(res=>{
                  getIncomes();
                  swal({
                    title: res.data.msg,
                    icon:'success'
                  })
                  
                })
        }else{
          return false
        }
      })
      

          }
   
    const columns = [
      {name: "inc_id",options: { display: 'excluded' }},
      {name:"SL",options: {filter: false,sort: false,
        customBodyRender:(value,tableMeta)=>{
        return ( <p>{parseFloat(tableMeta.rowIndex)+1}</p> ); 
      }
      },headerStyle: {
        textAlign:'left'
      }},
      {name: "inc_code",label: "Income Code",options: {filter: true,sort: true}},
      {name: "creation_date",label: "Income Date",options: {filter: true,sort: true,
        customBodyRender:(value,tableMeta)=>{
          return(<p>{ moment(tableMeta.rowData[3]).format(dateFormat) }</p>) 
        }
      }},
      {name: "acc_name",label: "Received Into",options: {filter: true,sort: true}},
      {name: "inc_total",label: "Income Total",options: {filter: true,sort: true}},
      {name: "narration",label: "Narration",options: {filter: true,sort: true}},
      {name: "user_name",label: "Created By",options: {filter: true,sort: true}},


      {name:"actions",options: {filter: false,sort: false,
        customBodyRender:(value,tableMeta)=>{
          return ( <ActionOptions   value={value} rowIndex={tableMeta.rowIndex}  rowData={tableMeta.rowData} 
             /> ); 
      }
      },headerStyle: {
        textAlign:'right'
      }}
     ];
            
     const options = {
       filterType: 'checkbox',
       selectableRows: 'none',
       display: "excluded"
      }


    return (
    <div className={classes.root}>

     <Paper className={classes.paper} style={{marginTop:'-15px'}}>
    <h2 className={classes.pageEntryLabel}>Income Entry </h2>
      <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
 


     


<Grid item xs={12} sm={12}>



<Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={loadFromAccounts} 
              size="small"
              classes={{
                option: classes.option,
              }}
              
              getOptionLabel={(option) => option.acc_name}
              value={selectedAccountFrom}
              onChange={(e,selectedObj)=>{
                selectedAccountFromSet(selectedObj)
                if (e.key === "Enter") {
                  inc_amount_ref.current.focus()
                 }
              }}
              renderInput={(params) => (
                <TextField
                  inputRef={acc_from_ref}
                  onChange={(e)=>{
                    account_name_from_set(e.target.value);
                   
                  }}
                  {...params}
                  label="Income Head"
                  variant="outlined"
                
                />
              )}
          />
     



      

</Grid>

<br/>
        <Grid item xs={12} sm={12}> 
        <TextField type="number"  
         inputRef={inc_amount_ref}
         onKeyDown={event => {
           if (event.key === "Enter") {
            addTOIncome()
           }
         }} autoComplete='off' className={classes.fullWidth} 
        label="Income Amount" name="inc_amount" value={inc_amount} 
        variant="outlined" size="small" onChange={(e)=>{

                    inc_amount_set(e.target.value)
                
           
            }} />

<span>Press Enter Key to income </span>

        </Grid>
        <br/>

        


        <Grid item xs={12} sm={6} style={{marginTop: '20px'}}>
           <Button onClick={()=>addTOIncome()} style={{background: 'rgb(109 113 113)',color: 'white',fontSize: '10px'}}>Add To income</Button>
        </Grid>


      </Grid>

      <Grid item xs={12} sm={8}>

        <Grid item xs={12} sm={12}>


            <Grid item xs={12} sm={5} style={{float:'left',width: '100%'}}>
     
     
<Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={loadToAccounts} 
              size="small"
              classes={{
                option: classes.option,
              }}
              
              getOptionLabel={(option) => option.acc_name}
              value={selectedAccountInto}
              onChange={(e,selectedObj)=>{
                selectedAccountIntoSet(selectedObj)
                code_ref.current.focus() 

                if (e.key === "Enter") {
                  income_date_ref.current.focus();
                }
              }}
              renderInput={(params) => (
                <TextField
                  inputRef={acc_into_ref}
                  onChange={(e)=>{
                    account_name_to_set(e.target.value);
                   
                  }}
                  {...params}
                  label="Receipt Into Account"
                  variant="outlined"
                
                />
              )}
          />



            </Grid>


            <Grid item xs={12} sm={3} style={{float:'right',marginTop: '-8px',marginLeft:'5px'}}> 
      
            
            <LocalizationProvider dateAdapter={AdapterDateFns} style={{marginTop: '0px'}}>
        <Stack spacing={3}>
          <DesktopDatePicker
           readOnly={authInfo.role == 'user' ? true : false}
            label="Income Date" 
            inputFormat={dateTimeFormat}
            inputRef={income_date_ref}
           
            value={incomeDate}
            onChange={(e)=>incomeDateChange(e)}
            renderInput={(params) => <TextField 
              onKeyDown={event => {
                if (event.key === "Enter") {
                    narration_ref.current.focus()
                }
            }}
            
              {...params} />}
          />
          
        </Stack>
        </LocalizationProvider>


        </Grid>


        <Grid item xs={12} sm={3} style={{float:'right',marginTop: '0px'}}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
        inputRef={code_ref}
        onKeyDown={event => {
            if (event.key === "Enter") {
              income_date_ref.current.focus()
            }
          }} 
        label="Income Code" name="income_code" value={income_code} 
        variant="outlined" size="small" onChange={(e)=>income_code_set(e.target.value)} />
        </Grid>


        
        </Grid>
     
     




        <Grid item xs={12} sm={12}>
        <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table" style={{marginTop: '20px'}}>
        <TableHead>
              <TableRow>
              <TableCell align="left"   style={{width:'1%'}}>SL</TableCell>
              <TableCell align="left"   style={{width:'20%'}}>Account</TableCell>
              <TableCell align="right"  style={{width:'10%'}}>Income Amount</TableCell>
              <TableCell align="right"  style={{width:'10%'}}>Actions</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
              {
                incomeCart.map((acc,sl)=>(<>
                   <TableRow key={sl} style={{background: sl == income_ind_id ? '#dedede':'',  transition: '1s'}}>
                  <TableCell align="left">{sl+parseFloat(1)}</TableCell>
                  <TableCell align="left">{acc.acc_name}</TableCell>
                  <TableCell align="right">{format(parseFloat(acc.inc_amount).toFixed(2))}</TableCell>
                  <TableCell align="right">
                  <EditIcon style={{cursor:'pointer',color:'#2e482e',marginLeft:'2px'}} onClick={()=>{editIncomeTran(acc,sl)}}></ EditIcon>
                  <span style={{width:'2px'}}> </span>
                  <RemoveCircleIcon style={{cursor:'pointer',color:'#582727',marginLeft:'2px'}} onClick={()=>{removeIncomeTran(sl)}}></ RemoveCircleIcon>
                  </TableCell>
              
                  </TableRow>
                </>))
              }

              {
                incomeCart.length > 0 ? (<>
                <TableRow style={{background: 'rgb(249 243 243)',transition: '1s'}}>
                    <TableCell align="right" colSpan={2} style={{fontWeight:'bold'}}>Total Income Amount : </TableCell>
                    <TableCell align="right" style={{fontWeight:'bold'}}> {format(parseFloat(_.sumBy(incomeCart,(tran)=>parseFloat(tran.inc_amount))).toFixed(2))}</TableCell>
                    <TableCell align="right">----------</TableCell>
                 </TableRow>
                </>):''
                 
              }
             
         


     
        </TableBody>
      </Table>
    </TableContainer>


    <TableContainer  style={{float:'right'}}>
      <Table className={classes.table} size="small" aria-label="a dense table">
     
          <TableRow > 
             <TableCell style={{width: '325px'}}><TextareaAutosize  
             ref={narration_ref}
            
               
               onChange={(e)=>{narration_set(e.target.value)}} value={narration}  name="narration" style={{float:'left',marginTop:'20px',width: '325px'}} aria-label="Narration..." rowsMin={3} placeholder="Narration..." />
             </TableCell>

             <TableCell colSpan={2}></TableCell>

           
          
          </TableRow>
        
      </Table>
    </TableContainer>


        </Grid>



        </Grid>


      


     



        

      
  
      </Grid>


  
         <Grid item xs={12}>
  <Button style={{marginTop: '25px'}}
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        style={{color: 'white',
          backgroundColor: '#115f5f'}}
        disabled={loadingSave}
        startIcon={<SaveIcon />}
        onClick={saveFormAction}
      >
        Save
      </Button>
  </Grid>         


     </Paper>

     {
      loadingList==true?(<b>Loading...</b>):(
        <Paper className={classes.paper} style={{marginTop:'20px'}}>
        <MUIDataTable
      title={"Income Entry List"}
      data={incomes}
      columns={columns}
      options={options}
      adjustForCheckbox={false} 
      displaySelectAll={false}
      />
      </Paper>
      )
      
     }

     
    </div>
        
    );
}


 const options = {
   filterType: 'checkbox',
 };
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },

root: {},
'& .MuiTextField-root': {
  margin: theme.spacing(1),
  width: '25ch',
 },
  paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
  },
  pageEntryLabel:{
      color: '#7754cc',
      margin: '0px',
      padding: '0px',
      marginTop: '-11px',
      textAlign: 'left',
      marginLeft: '0px',
      marginBottom: '5px'
  },
  fullWidth:{
      width:'100%'
  },
  option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    plusLinkDiv:{
        position:'relative'  
  },
  plusLink:{
    margin: 0,
    padding: 0,
    marginTop: '-21px',
    fontSize: '29px',
    height: '21px',
    textAlign: 'right',
    position: 'absolute',
    right: 0,
    color: '#3e8d54'
  }
}));

const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(GroupManage);