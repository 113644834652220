import React,{ useState,useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {connect} from 'react-redux';
import swal from 'sweetalert';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MUIDataTable from "mui-datatables";
import {API_URL} from '../../config.json';
import axios from 'axios';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';


import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import {pathSpliter,authInfo} from '../../lib/functions'

import {currentRouteSet} from '../../actions/actions';


const GroupManage = ({location,currentRouteSet,currentRoute,authInfo})=>{
    const classes = useStyles();
 
  useEffect(()=>{
    getDepartments();
    currentRouteSet(pathSpliter(location.pathname,1));
    
  },[])

    // Init states start
 
    let [departments,departmentsSet] = useState([]);
    
    let [loadingList,loadingListSet] = useState(false)
    let [loadingSave,loadingSaveSet] = useState(false)

    let [action,action_set] = useState('create');
    let [department_id,department_id_set] = useState(0);
    let [department_name,department_name_set] = useState('');

    const department_name_ref = useRef(null);
    
    


    // Init states end
    // Methods script start
    const saveFormAction = async ()=>{
          if(department_name.trim() == ''){
            swal({
              title:'department Name is Required.',
              icon:'warning'
            })
          }else{
            loadingSaveSet(true);
            await axios.post(`${API_URL}/api/save-department`,{
              department_name:department_name.trim(),
              action,
              department_id
            },{headers:{'auth-token': authInfo.token}}).then(res=>{
              loadingSaveSet(false);
              getDepartments()
              if(res.data.error){
                swal({
                  title: res.data.msg,
                  icon:'warning'
                })
              }else{
                swal({
                  title: res.data.msg,
                  icon:'success'
                })

                department_id_set(0)
                department_name_set('')
                action_set('create')


              }
              
            });
          }
          
    }


  

  
    const getDepartments = async ()=>{
      await axios.post(`${API_URL}/api/get-departments`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
        departmentsSet(res.data)
      })
    }

   


    // Methods script end
    const ActionOptions = (props)=>{
      return(<div style={{textAlign:'right'}}> 

{
    authInfo.role !='user'?(
    <>
        <EditIcon style={{cursor:'pointer',fontSize: '28px',color: 'rgb(15, 126, 119)'}} onClick={()=>departmentEdit(props.rowData)}/>
      
        <DeleteIcon style={{cursor:'pointer',color: '#ff0202',fontSize: '28px'}} onClick={()=>departmentDelete(props.rowData)}/>
            
   </>):''
 }
      
      </div>)
    
    }


    const departmentEdit = (row,index)=>{
      let department =  departments.filter((department)=>department.id == row[0])
      department_id_set(department[0].id)
      department_name_set(department[0].name)
      action_set('update')
    }


    const departmentDelete = async (row)=>{
      swal({
        title:'Are you sure delete this?',
        icon:'warning',
        buttons:true
      }).then(async(yes)=>{
        if(yes){
          let department =  departments.filter((department)=>department.id == row[0])
          await axios.post(`${API_URL}/api/delete-department`,{department_id:department[0].id},
          {headers:{'auth-token':authInfo.token}}).then(res=>{
                  getDepartments();
                  swal({
                    title: res.data.msg,
                    icon:'success'
                  })
                  
                })
        }else{
          return false
        }
      })
      

          }
   
    const columns = [
      {name: "id",options: { display: 'excluded' }},
      {name:"SL",options: {filter: false,sort: false,
        customBodyRender:(value,tableMeta)=>{
        return ( <p>{parseFloat(tableMeta.rowIndex)+1}</p> ); 
      }
      },headerStyle: {
        textAlign:'left'
      }},
      {name: "name",label: "Department Name",options: {filter: true,sort: true}},
      
      {name:"actions",options: {filter: false,sort: false,
        customBodyRender:(value,tableMeta)=>{
          return ( <ActionOptions   value={value} rowIndex={tableMeta.rowIndex}  rowData={tableMeta.rowData} 
             /> ); 
      }
      },headerStyle: {
        textAlign:'right'
      }}
     ];
            
     const options = {
       filterType: 'checkbox',
       selectableRows: 'none',
       display: "excluded"
      }




    return (
    <div className={classes.root}>

     <Paper className={classes.paper} style={{marginTop:'-15px'}}>
    <h2 className={classes.pageEntryLabel}>Department Entry </h2>
      <Grid container spacing={2}>
      
        <Grid item xs={12} sm={3}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
        inputRef={department_name_ref}
        onKeyDown={event => {
          if (event.key === "Enter") {
            saveFormAction()
          }
        }}
        label="Department Name" name="department_name" value={department_name} variant="outlined" size="small" onChange={(e)=>department_name_set(e.target.value)} />
        </Grid>

  
  </Grid>


  
         <Grid item xs={12}>
  <Button style={{marginTop: '25px'}}
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        disabled={loadingSave}
        startIcon={<SaveIcon />}
        onClick={saveFormAction}
      >
        Save
      </Button>
  </Grid>         


     </Paper>

     {
      loadingList==true?(<b>Loading...</b>):(
        <Paper className={classes.paper} style={{marginTop:'20px'}}>
        <MUIDataTable
      title={"Department List"}
      data={departments}
      columns={columns}
      options={options}
      adjustForCheckbox={false} 
      displaySelectAll={false}
      />
      </Paper>
      )
      
     }

     
    </div>
        
    );
}


 const options = {
   filterType: 'checkbox',
 };
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },

root: {},
'& .MuiTextField-root': {
  margin: theme.spacing(1),
  width: '25ch',
 },
  paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
  },
  pageEntryLabel:{
      color: '#7754cc',
      margin: '0px',
      padding: '0px',
      marginTop: '-11px',
      textAlign: 'left',
      marginLeft: '0px',
      marginBottom: '5px'
  },
  fullWidth:{
      width:'100%'
  },
  option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    }
}));

const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(GroupManage);