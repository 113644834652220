import React,{useState,useEffect,useRef} from 'react';
import {connect} from 'react-redux';

import {currentRouteSet} from '../../actions/actions';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid'; 
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import {pathSpliter,_p,sleep,dateTimeFormat,dateFormat,currentDateTime,getDateTimeFromISODT,currentDateStartTime,currentDateEndTime} from '../../lib/functions'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DdatateIcon from '@material-ui/icons/Delete';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DateFnsUtils from '@date-io/date-fns'; // choose your libs
import {API_URL} from '../../config.json';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import _ from 'lodash';
import ReactToPrint from "react-to-print";
import PrintIcon from '@material-ui/icons/Print';
import InstitutionProfile from '../commons/institution_profile'
import '../commons/commons.css'

import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';


import swal from 'sweetalert';
import commaNumber from 'comma-number';
import { TableFooter } from 'semantic-ui-react';
let format = commaNumber.bindWith(',', '.')
const SalesRecord = ({location,currentRoute,currentRouteSet,authInfo})=>{
    const classes = useStyles();
    useEffect(()=>{
        currentRouteSet(pathSpliter(location.pathname,1));
    },[]);

    let [report,reportSet] = useState([]);

    let [reportLoading,reportLoadingSet] = useState(false);

    
    let [filterTypes,filterTypesSet] = useState([{filterType:'Lifetime'},{filterType:'Date Wise'}
  ]);


  
    let [selectedFilterType,selectedFilterTypeSet] = useState({filterType:'Date Wise'})

    let [balances,balancesSet] = useState({
        purchaseBalance : 0,
        productTransferReceivedBalance : 0,
        purchaseReturnBalance : 0,
        salesBalance : 0,
        productTransferBalance : 0,
        salesReturnBalance : 0,
        directIncomeBalance : 0,
        directExpenseBalance : 0,
        currentInventoryBalance : 0,

        itemSoldAmount : 0,
        itemCostingAmount : 0,
        item_profit_reversal : 0,
        itemProfitLoss : 0,

        debitAmount : 0,
        creditAmount : 0,
        grossProfitAmount : 0,
        indirectIncomeBalance : 0,
        indirectExpenseBalance : 0,
        netProfit : 0,
        debitTotal : 0,
        creditTotal : 0,
    })

   
    

    

    

    let [byDateTime,byDateTimeSet] = useState({
      dateTimeFrom: currentDateStartTime(),
      dateTimeTo:currentDateEndTime()
})

    

    let [print,printSet] = useState(false);


    let getBalances = async ()=>{

     
        reportLoadingSet(true)
        let url =  `${API_URL}/api/`;

        let payLoad = {
          selectedFilterType: selectedFilterType != null?selectedFilterType.filterType:null,

        }

        if(selectedFilterType!=null && (selectedFilterType.filterType=='Date Wise' )){
          payLoad.fromDate = byDateTime.dateTimeFrom
          payLoad.toDate = byDateTime.dateTimeTo
        }
        // balances start
        await axios.post(`${url}get-profit-loss-balance`,payLoad,{headers:{'auth-token':authInfo.token}}).then(res=>{
            balances.salesBalance = res.data.salesBalance;
            balances.productTransferBalance = res.data.productTransferBalance;
            balances.productTransferReceivedBalance = res.data.productTransferReceivedBalance;
            balances.serviceBalance = res.data.serviceBalance;
            balances.salesReturnBalance = res.data.salesReturnBalance;
            balances.purchaseBalance = res.data.purchaseBalance;
            balances.serviceExpenseBalance = res.data.serviceExpenseBalance;
            balances.purchaseReturnBalance = res.data.purchaseReturnBalance;
            balances.directIncomeBalance = res.data.directIncomeBalance;
            balances.directExpenseBalance = res.data.directExpenseBalance;
            balances.currentInventoryBalance = res.data.currentInventoryBalance;
            balances.debitAmount = res.data.debitAmount;
            balances.creditAmount = res.data.creditAmount;
            balances.grossProfitAmount = res.data.grossProfitAmount;

            balances.itemSoldAmount = res.data.itemSoldAmount;
            balances.itemCostingAmount = res.data.itemCostingAmount;
            balances.item_profit_reversal = res.data.item_profit_reversal;
            
            balances.itemProfitLoss = res.data.itemProfitLoss;


            balances.indirectIncomeBalance = res.data.indirectIncomeBalance;
            balances.indirectExpenseBalance = res.data.indirectExpenseBalance;
            balances.netProfit = res.data.netProfit;

            balances.debitTotal = res.data.debitTotal;
            balances.creditTotal = res.data.creditTotal;
        })

        
        // Assets End
        printSet(true)
        reportLoadingSet(false)

    }




    let ReportDom = React.forwardRef( (props,ref) =>{
        return(
          <div ref={ref} >
                        <InstitutionProfile />

<div style={{clear:'both'}}></div>
<Paper style={{paddingLeft:'10px',paddingRight:'10px'}} className="print-source">
<p style={{width:'100%',textAlign:'center',fontWeight:'bold',
    textAlign: 'center',
    fontSize: '24px',
    margin: '0',
    padding: '0',
    fontStyle: 'italic',
    fontFamily: 'fantasy',
    color: '#1c721c'}}>Profit Loss</p>
<div style={{float:'left',width:'50%',textAlign:'left',margin:0,padding:0,fontSize:'16px'}}>
</div>

<div style={{width:'10%'}}></div>

<div style={{float:'right',width:'40%',textAlign:'left',margin:0,padding:0,fontSize:'16px'}}>
{/* <p style={{margin:'0px'}}>Statement  :  { moment(byDate.dateFrom).format(dateFormat)  } - { moment(byDate.dateTo).format(dateFormat) }</p> */}
</div>
 


</Paper> 

{
    
    <Paper className={classes.paper}  style={{paddingLeft:'10px',paddingRight:'10px',marginTop:'1px',marginBottom:'1px'}}>
    
    <Grid container spacing={3}>
       

         <Grid item  xs={12}   sm={6} >
        {/*  Start */}
         <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table" className="report-dom">
        <TableHead>
          <TableRow style={{background: '#'}}> 
            <TableCell style={{color:'#fff !important',background: 'green'}} align="left"  width="5%">PARTICULARS</TableCell>
            <TableCell style={{color:'white',background: 'green'}} align="right" width="15%">Debit</TableCell> 
          </TableRow>
        </TableHead>
        <TableBody>
                <TableRow  key="1"> 
                <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Purchase Account</TableCell>
                <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold'}}>{format(parseFloat(balances.purchaseBalance).toFixed(2))}</TableCell>
                </TableRow>

                <TableRow  key="1"> 
                <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Product Transfer Received Amount</TableCell>
                <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold'}}>{format(parseFloat(balances.productTransferReceivedBalance).toFixed(2))}</TableCell>
                </TableRow>

                <TableRow  key="1"> 
                <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Service Expense Account</TableCell>
                <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold'}}>{format(parseFloat(balances.serviceExpenseBalance).toFixed(2))}</TableCell>
                </TableRow>

                <TableRow  key="1"> 
                <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Sales Return</TableCell>
                <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold'}}>{format(parseFloat(balances.salesReturnBalance).toFixed(2))}</TableCell>
                </TableRow>

                <TableRow  key="1"> 
                <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Direct Expense</TableCell>
                <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold'}}>{format(parseFloat(balances.directExpenseBalance).toFixed(2))}</TableCell>
                </TableRow>

                


                <TableRow  key="1"> 
                <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Product Wise Profit </TableCell>
                <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold'}}>( Costing : {format(parseFloat(balances.itemCostingAmount).toFixed(2))} , Sold : {format(parseFloat(balances.itemSoldAmount).toFixed(2))} , Reversal : { format(parseFloat(balances.item_profit_reversal))  } ) =     {format(parseFloat(balances.itemProfitLoss).toFixed(2))}</TableCell>
                </TableRow>


                
                  
            <TableRow style={{background: 'rgb(140 187 111)'}}> 
            <TableCell   align="left"  width="5%" style={{fontWeight:'bold',color:'white !important',fontSize: '14px',fontWeight: 'bold'}}>Total Debit Amount: </TableCell>
            <TableCell style={{fontWeight:'bold',color:'white !important',fontSize: '14px',fontWeight: 'bold'}} align="right" width="15%">{
                format(parseFloat(balances.debitAmount).toFixed(2))
            }</TableCell> 
            </TableRow>

            <TableRow  key="1"> 
                <TableCell align="left">----</TableCell>
                <TableCell  align="right">----</TableCell>
                </TableRow>


      

                <TableRow  key="1" > 
                <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Product Profit  Amount</TableCell>
                <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold'}}>{authInfo.currency} {format(parseFloat(balances.itemProfitLoss).toFixed(2))}</TableCell>
                </TableRow>


            
            <TableRow  key="1"> 
                <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Indirect Expense : </TableCell>
                <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold'}}>{format(parseFloat(balances.indirectExpenseBalance).toFixed(2))}</TableCell>
                </TableRow>


                
                <TableRow style={{background:  balances.netProfit < 0 ? '#e55a5a':'#2daf1d'}} key="1"> 
                <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Net Profit/ Loss : </TableCell>
                <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold'}}>{authInfo.currency} {format(parseFloat(balances.netProfit).toFixed(2))}</TableCell>
                </TableRow>

                <TableRow style={{background: '#b7d1b8'}}> 
                    <TableCell style={{fontWeight:'bold',fontSize: '14px',fontWeight: 'bold'}} align="left"  width="5%"> Total Debit : </TableCell>
                    <TableCell style={{fontWeight:'bold',fontSize: '14px',fontWeight: 'bold'}} align="right" width="15%">
                            {
                                 format(parseFloat(balances.debitTotal).toFixed(2))
                            }
                    </TableCell> 
                    </TableRow>



            </TableBody>
            </Table>
           </TableContainer>
           {/* End */}
         </Grid>

          <Grid item  xs={12}   sm={6} >
          <TableContainer >
            <Table className={classes.table} size="small" aria-label="a dense table" className="report-dom">
                <TableHead>
                <TableRow> 
                    <TableCell align="left"  width="5%">PARTICULARS</TableCell>
                    <TableCell align="right" width="15%">Credit</TableCell> 
                </TableRow>
                </TableHead>
                <TableBody>

                       <TableRow  key="1"> 
                        <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Purchase Return</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold'}}>{format(parseFloat(balances.purchaseReturnBalance).toFixed(2))}</TableCell>
                        </TableRow>

                        <TableRow  key="1"> 
                        <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}} >Sales Account</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold'}}>{format(parseFloat(balances.salesBalance).toFixed(2))}</TableCell>
                        </TableRow>


                        <TableRow  key="1"> 
                <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Product Transfer  Amount</TableCell>
                <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold'}}>{format(parseFloat(balances.productTransferBalance).toFixed(2))}</TableCell>
                </TableRow>


                        <TableRow  key="1"> 
                      <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Service  Account</TableCell>
                      <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold'}}>{format(parseFloat(balances.serviceBalance).toFixed(2))}</TableCell>
                      </TableRow>

                        <TableRow  key="1"> 
                <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Direct Income</TableCell>
                <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold'}}>{format(parseFloat(balances.directIncomeBalance).toFixed(2))}</TableCell>
                </TableRow>

                <TableRow  key="1"> 
                <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Closing Inventory</TableCell>
                <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold'}}>{format(parseFloat(balances.currentInventoryBalance).toFixed(2))}</TableCell>
                </TableRow>

              

                        
                    <TableRow style={{background: 'rgb(140 187 111)'}} > 
                    <TableCell style={{fontWeight:'bold',fontSize: '14px'}} align="left"  width="5%">Total Credit Amount : </TableCell>
                    <TableCell style={{fontWeight:'bold',fontSize: '14px'}} align="right" width="15%">
                            {
                                 format(parseFloat(balances.creditAmount).toFixed(2))
                            }
                    </TableCell> 
                    </TableRow>


                    {/*  */}

                    <TableRow  key="1"> 
                <TableCell align="left">----</TableCell>
                <TableCell  align="right">----</TableCell>
                </TableRow>



                    <TableRow  key="1"> 
                <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Previous Gross Profit/Loss</TableCell>
                <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold'}}>{format(parseFloat(balances.netProfit).toFixed(2))}</TableCell>
                </TableRow>


                
                <TableRow  key="1"> 
                <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Indirect Income : </TableCell>
                <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold'}}>{format(parseFloat(balances.indirectIncomeBalance).toFixed(2))}</TableCell>
                </TableRow>

                <TableRow style={{background: '#b7d1b8'}}> 
                    <TableCell style={{fontSize: '14px',fontWeight:'bold'}} align="left"  width="5%"> Total Credit : </TableCell>
                    <TableCell style={{fontSize: '14px',fontWeight:'bold'}} align="right" width="15%">
                            {
                                 format(parseFloat(balances.creditTotal).toFixed(2))
                            }
                    </TableCell> 
                    </TableRow>


                    </TableBody>
                    </Table>
                </TableContainer>
          </Grid>
          
          </Grid>
    
        </Paper>
  
    
}
    


      
     
<div style={{clear: 'both',height:'10px'}}></div>
<div  className="print-source" style={{marginTop:'70px'}}>
                                <p style={{float:'left',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Received By</p>
                           
                                 <p style={{float:'right',marginRight:'10px',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Authorized By</p>
                      </div>
          </div>
        )
    })




    const reportRef = useRef();

   

      return(
          <div className={classes.root}> 
<Paper className={classes.paper} style={{marginTop:'-25px',marginBottom:'5px'}}>
<h4 style={{ textAlign: 'left',margin: "0px 0px  7px 0px",padding: '0px',color: '#222', textAlign: 'center',
    fontSize: '24px',
    margin: '0',
    padding: '0',
    fontStyle: 'italic',
    fontFamily: 'fantasy',
    color: '#1c721c',marginBottom:'20px'}}>Profit Loss Report { reportLoading == true ? ' --- Loading...':'' }</h4>




<Paper className={classes.paper} style={{marginTop:'-10px',marginBottom:'0px'}}>
            <Grid container>
                <Grid item  xs={12}  sm={2}> 
          <Autocomplete 
          size="small"
          autoHighlight
              openOnFocus={true}
              style={{width:'100%',height:'20px'}}
              options={filterTypes} 
              value={selectedFilterType}
              getOptionLabel={(option) => option.filterType}
              onChange={(event,selectedObj)=>{
                selectedFilterTypeSet(selectedObj) 
              }}
              renderInput={(params) => <TextField 
               
                {...params} 
                label="Filter Type" 
                variant="outlined"
                />} 
          />

          </Grid>
              
          <div  style={{width: '20px'}} > </div>


          <Grid item  xs={12}  sm={2} style={{display: selectedFilterType!=null && (selectedFilterType.filterType=='Date Wise' )?'block':'none'}} > 
          
          



          <LocalizationProvider dateAdapter={AdapterDateFns}>
    <Stack spacing={3}>
      <DesktopDatePicker
        label="From Date" 
        inputFormat={dateTimeFormat}
        value={byDateTime.dateTimeFrom}
        onChange={(datet)=>{
          byDateTimeSet({...byDateTime,dateTimeFrom:datet})
        }}
        renderInput={(params) => <TextField {...params} />}
      />
      
    </Stack>
  </LocalizationProvider>


        </Grid>
        <div  style={{width: '20px'}} > </div>


        <Grid item  xs={12}  sm={2} style={{display: selectedFilterType!=null && (selectedFilterType.filterType=='Date Wise' )?'block':'none'}}  > 


        

<LocalizationProvider dateAdapter={AdapterDateFns}>
    <Stack spacing={3}>
      <DesktopDatePicker
        label="To Date" 
        inputFormat={dateTimeFormat}
        value={byDateTime.dateTimeTo}
        onChange={(datet)=>{
          byDateTimeSet({...byDateTime,dateTimeTo:datet})
        }}
        renderInput={(params) => <TextField {...params} />}
      />
      
    </Stack>
  </LocalizationProvider>

        </Grid>

        <div  style={{width: '20px'}} > </div>

        <Grid item  xs={12}   sm={1} >
      <Button style={{marginTop: '5px',marginLeft: 'auto',fontSize:'10px'}} 
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          startIcon={<SearchIcon/>}
                          onClick={getBalances}
                          disabled={reportLoading?true:false}
                      >
                      Report 
                    </Button>
      </Grid>
               
            </Grid>
        </Paper>

        </Paper>
        {
          print?(
            <Grid container >
            <Grid item xs={12} sm={12} >
              <Paper style={{borderRadius:'0px',marginTop: '-17px'}}>
              <ReactToPrint
                          trigger={() => <PrintIcon  style={{cursor:'pointer',marginLeft: '30px',marginTop: '3px',marginBottom: '-8px'}} />}
                          content={() => reportRef.current}
                        />
              </Paper>
           
            </Grid>
        </Grid>
          ):''
        }
      
            <ReportDom ref={reportRef} /> 




          </div>
      )
}



const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
        color: 'white',
        fontSize:'10px',
        backgroundColor: '#115f5f'
      },
    
    root: {
      flexGrow: 1,
    },
    inputField:{
      width:'100%',
      marginTop:'5px'
    },
    plusLinkDiv:{
      position:'relative'  
    },
    plusLink:{
      margin: 0,
      padding: 0,
      marginTop: '-21px',
      fontSize: '29px',
      height: '21px',
      textAlign: 'right',
      position: 'absolute',
      right: 0,
      color: '#3e8d54'
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));


const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(SalesRecord); 
