import React,{useState,useEffect,useRef} from 'react';
import {connect} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import {API_URL} from '../../../config.json';
import InvoiceBody from './sales_voucher_body'
import {currentDateTime,convertNumberToWords, dateTimeFormat,dateFormat,dateWithTimeFormat} from '../../../lib/functions'
import PrintIcon from '@material-ui/icons/Print';
import moment from 'moment';
import ReactToPrint from "react-to-print";
import Font, {Text} from 'react-font'

import '../../commons/voucher.css'
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')

class PrintAbleSection extends React.Component {
      
  constructor(props) {
    super(props); 


  }

  state = {
    sales:[],
    institution:null,
    accDue:0,
    printPad:'yes',
  }

  componentDidMount() {
    this.getSalesOrder()
    this.setState({institution:this.props.institution});
    
  }

 

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.sale_id != this.props.sale_id) {
        this.getSalesOrder()
    }

  }
   
   getSalesOrder = async()=>{
      let accId = null
      await axios.post(`${API_URL}/api/get-sales-record-with-details`,{sale_id:this.props.sale_id,from:'voucher'},{headers:{'auth-token':this.props.authInfo.token}}).then(res=>{
       if(res.data.length == 0) return false

        // Detail Data to Product Cart - Start

        accId = res.data[0].acc_id
      let itemCartData =  res.data[0].details.map((item)=>{ 
        let serials = []
        
        serials = item.serials.split(',');
        if(item.is_serial == 'yes' ){
          serials = serials.map((slNo)=>{
            return {serial_number : slNo}
          })
        }else{
          serials = []
        }

          let updateItem = {
            warehouse_id : item.warehouse_id,
            warehouse_name : item.warehouse_name,
            item_id : item.item_id,
            item_name : item.item_name,
            display_text : item.display_text,
            is_serial: item.is_serial,
            item_qty:item.item_qty,
            item_rate:item.item_rate,
            item_discount:item.item_discount,
            item_discount_per:item.item_discount_per,
            discount_acc_id:item.discount_acc_id,
            discount_acc_name:item.discount_acc_name,
            item_tax:item.item_tax,
            item_tax_per:item.item_tax_per,
            tax_acc_id:item.tax_acc_id,
            tax_acc_name:item.tax_acc_name,
            serials,
            item_total:item.item_total,
            retail_qty:item.retail_qty,
            sale_qty:item.sale_qty,
            sale_rate:item.sale_rate,
            qty_display: item.item_qty +' '+ item.unit_symbol + (item.conversion >1 ? ', '+ item.retail_qty +' ' +item.base_unit_name:''),
            done_qty_display: item.done_item_qty +' '+ item.unit_symbol + (item.conversion >1 ? ', '+ item.done_retail_qty +' ' +item.base_unit_name:''),
            unit_symbol: item.unit_symbol,
            base_unit_name: item.base_unit_name,
            unit_name: item.unit_name,
            conversion: item.conversion,
            serial_note : item.serial_note,
            narration : item.narration
          }
          return updateItem;
        });

        res.data[0].details = itemCartData

        this.setState({sales:res.data[0]});
        
      });

      await axios.post(`${API_URL}/api/get-sundry-debitor-balance`,{customerId:accId},{headers:{'auth-token':this.props.authInfo.token}}).then( (res)=>{
        if(res.data.accounts.length ==0){
          this.setState({accDue:0})
          return false
        } 
        this.setState({accDue:res.data.accounts[0].balance})
       })

      
  }



  render(){
    let {sales,institution,accDue}  = this.state;
    return(
      <div style={{padding:'20px',paddingBottom:'0px'}} >
        {
          sales.length!=0?(
              <>
                <Grid container className={"invoice-title"}>

                              <Grid  item xs={12} sm={12}>
                              <h3 style={{textAlign:'center',margin: '0',
    padding: '0',
    marginTop: '11px'}}>INVOICE</h3>
                              </Grid>

                      
                           
                      </Grid>
                      <Grid container style={{marginBottom: '5px',fontSize:'12px',color:"#222",marginTop:'5px'}}>
                           <Grid item xs={6} sm={6}> 
                                 {/* <strong>Customer/ Debtor Code : </strong> <span>{ sales.acc_code }</span><br/> */}
                                 <strong> Name : </strong> <span>{ sales.acc_name }</span><br/>
                                 {/* <strong>Institution  : </strong> <span>{ sales.institution_name }</span><br/> */}
                                 <strong> Address : </strong> <span>{ sales.address }</span><br/>
                                 <strong> Contact No : </strong> <span>{ sales.contact_no }</span><br/>
                            </Grid>
                            <Grid item xs={6} sm={6} style={{textAlign:'right'}}>
                                <strong>Invoice No : </strong> <span>{ sales.sale_voucher_no }</span><br/>  
                                 <strong>Sales Date  : </strong> <span>{ moment(sales.created_date).format(dateWithTimeFormat)  }</span><br/>
                                 <strong>Sales By  : </strong> <span>{ sales.user_full_name }</span><br/>
                               {/* {sales.group_name !=  ''  ?<> <strong>Group Name  : </strong> <span>{ sales.group_name }</span> </> :""} <br/> */}

                            </Grid>
                      </Grid>
                     
                      <InvoiceBody salesOrderData={sales} institution={institution} authInfo={this.props.authInfo}/>

                      <Grid container>


                  
                            <Grid xs={6} sm={6} style={{marginTop: '5px'}}>


                                  <table>
                                        <tr>
                                          <td style={{fontWeight:'normal'}}>Previous Due : { format(Math.abs(parseFloat(accDue-sales.due_amount).toFixed(2))) } </td>
                                        </tr>
                                        <tr>
                                          <td style={{fontWeight:'normal'}}> Invoice Due : {format(parseFloat(sales.due_amount).toFixed(2))} </td>
                                        </tr>
                                        <tr >
                                          <td style={{background: '#ccc',height: '0px'}}></td>
                                        </tr>
                                        <tr>
                                          <td style={{fontWeight:'bold'}}>Current Due : {format(parseFloat(accDue).toFixed(2))} </td>
                                        </tr>
                                  </table>
                          




                              
                                  <table style={{width:'100%',paddingRight: '20px'}}>
                                    <thead>
                                      {
                                        sales.paid_amount != 0 ?(<>
                                        <tr>
                                        <th colSpan={3} style={{width:'100%',textAlign:'left'}}>Paid - Received Into Accounts</th>
                                        </tr>
                                        <tr>
                                        <th style={{width:'2%',textAlign:'left'}}>SL</th>
                                        <th style={{width:'40%',textAlign:'left'}}> Cash/ Bank Account </th>
                                        <th style={{width:'30%',textAlign:'left'}}>  Narration </th>
                                        <th style={{width:'28%',textAlign:'left'}}> Amount </th>
                                        </tr>
                                        </>):''
                                      }
                                   
                                    </thead>
                                        <tbody>
                                       
                                          {
                                           sales.trans != undefined ?  sales.trans.map((tran,sl)=>(<>
                                                 <tr>
                                                  <td style={{borderBottom: '1px solid #d1d0d0'}}>{sl+parseFloat(1)}</td>
                                                  <td style={{borderBottom: '1px solid #d1d0d0'}}>{tran.to_acc_name}</td>
                                                  <td style={{borderBottom: '1px solid #d1d0d0'}}>{tran.acc_note}</td>
                                                  <td style={{borderBottom: '1px solid #d1d0d0'}}>{format(parseFloat(tran.tran_amount).toFixed(2))}</td>
                                                  </tr>
                                            </>)) :''
                                          }
                                        </tbody>
                                       

                                  </table>

{/* {
   isNaN(sales.day_week) ? (<>
      <table style={{width:'100%',paddingRight: '20px'}}>


<tr>
      <th style={{width:'15%',textAlign:'left'}}>Payment Duration</th>
      <th style={{width:'15%',textAlign:'left'}}>{sales.component_name}</th>
      <th style={{width:'30%',textAlign:'left'}}> Qty:  {sales.day_week}  </th>
      <th style={{width:'30%',textAlign:'left'}}> Amount :  {sales.emi_amount} </th>
      </tr>

</table>

  </>):''
} */}
                                  
{
  sales.emi_month != 0 ? (<>
  
  <table style={{width:'100%',paddingRight: '20px'}}>
                                    <thead>
                                      {
                                        sales.is_emi == 'yes' ?(<>
                                        <tr>
                                        <th colSpan={3} style={{width:'100%',textAlign:'left'}}> {sales.emi_month} Installments Payment</th>
                                        </tr>
                                        <tr>
                                        <th style={{width:'15%',textAlign:'left'}}>EMI No</th>
                                        <th style={{width:'25%',textAlign:'left'}}> From Date </th>
                                        <th style={{width:'30%',textAlign:'left'}}> Last Pay Date </th>
                                        <th style={{width:'30%',textAlign:'left'}}> Amount </th>
                                        </tr>
                                        </>):''
                                      }
                                   
                                    </thead>
                                        <tbody>
                                       
                                          {
                                           sales.emis != undefined ?  sales.emis.map((tran,sl)=>(<>
                                                 <tr>
                                                  <td style={{borderBottom: '1px solid #d1d0d0'}}>{tran.emi_no}</td>
                                                  <td style={{borderBottom: '1px solid #d1d0d0'}}>{moment(tran.from_date).format(dateFormat) }</td>
                                                  <td style={{borderBottom: '1px solid #d1d0d0'}}>{ moment(tran.last_date).format(dateFormat) }</td>
                                                  <td style={{borderBottom: '1px solid #d1d0d0'}}>{format(parseFloat(tran.amount).toFixed(2))}</td>
                                                  </tr>
                                            </>)) :''
                                          }
                                        </tbody>
                                       

                                  </table>
  
  </>) :''
}

                                 
                            </Grid>
                            <Grid xs={6} sm={6} style={{marginTop: '2px'}}>
                                      <table style={{width:'100%'}}> 

                  <tr>
                                          <td style={{color: '#222',fontWeight:'normal'}}>Quantity Total : ({sales.details.reduce((prev,curr)=>{ return prev+parseFloat(curr.sale_qty)},0)}) </td>
                                          <td style={{textAlign:"right",color: '#222',fontWeight:'normal'}}>  </td>
                                        </tr>
                                        <tr>
                                          <td style={{color: '#222',fontWeight:'normal'}}>Amount Sub Total : </td>
                                          <td style={{textAlign:"right",color: '#222',fontWeight:'normal'}}> {format(parseFloat(sales.sub_total).toFixed(2))} </td>
                                        </tr>
                                     {
                                       sales.is_cal_type == 'on_total'?(<>
                                        <tr>
                                          <td style={{color: '#222',fontWeight:'normal'}}>Vat Amount:</td>
                                          <td style={{textAlign:"right",color: '#222',fontWeight:'normal'}}> {format(parseFloat(sales.total_tax).toFixed(2))} </td>
                                        </tr>
                                        <tr>
                                          <td style={{color: '#222',fontWeight:'normal'}}>Discount Amount:</td>
                                          <td style={{textAlign:"right",color: '#222',fontWeight:'normal'}}> {format(parseFloat(sales.total_discount).toFixed(2))} </td>
                                        </tr>
                                       </>):''
                                     }
                                       
                                        <tr>
                                          <td style={{color: '#222',fontWeight:'normal'}}>Transport Cost : </td>
                                          <td style={{textAlign:"right",color: '#222',fontWeight:'normal'}}> {format(parseFloat(sales.total_transport_cost).toFixed(2))} </td>
                                        </tr>
                                       
                                        <tr >
                                        <td style={{width:'100%',borderTop: '1px solid #222'}}></td>
                                          <td style={{width:'100%',borderTop: '1px solid #222'}}></td>
                                        </tr>
                                        <tr>
                                          <td style={{color: '#222',fontWeight:'bold'}}>Grand Total : {this.props.authInfo.currency}</td> 
                                          <td style={{textAlign:"right",color: '#222',fontWeight:'bold'}}>  { format(parseFloat(sales.total_amount).toFixed(2))} </td>
                                        </tr>

                                        <tr >
                                        <td style={{width:'100%',borderTop: '1px solid #222'}}></td>
                                          <td style={{width:'100%',borderTop: '1px solid #222'}}></td>
                                        </tr>

                                      

                                    

                                        <tr>
                                          <td style={{color: '#222',fontWeight:'bold'}}>Paid : {this.props.authInfo.currency}</td> 
                                          <td style={{textAlign:"right",color: '#222',fontWeight:'bold'}}>  { format(parseFloat(sales.paid_total).toFixed(2))} </td>
                                        </tr>


                                        <tr>
                                          <td style={{color: '#222',fontWeight:'bold'}}>Due : {this.props.authInfo.currency}</td> 
                                          <td style={{textAlign:"right",color: '#222',fontWeight:'bold'}}>  { format(parseFloat(sales.due_total).toFixed(2))} </td>
                                        </tr>
                                      
                                  </table>


                                  {/*  */}


                                  
                            </Grid>



                            

                            <Grid xs={12} sm={12}>
                                  <p style={{color: '#222'}}>In Word of Grand Total : {convertNumberToWords(sales.total_amount)} </p>
                                  <p style={{color: '#222'}}>Narration : <p style={{whiteSpace: 'pre-line',margin: '0',marginLeft: '10px',padding: '0'}}>{sales.narration}</p> </p>
                            </Grid>

                      </Grid>
              </>

          ):''
        }
         
      </div>
    )
  }
}


class ComponentToPrint extends React.Component {

   
  constructor(props) {
    super(props);  
     }
  state = {
    authInfo:this.props.authInfo,
    sale_id:this.props.sale_id,
    institution:this.props.institution,
  }

 


  render() {
      let institution =   this.props.institution;
      let a4css = `
      .a4 {
        font-size: 15px;
        
    }
    .a4 body, table{
        font-size: 15px;
    }
      `
  
      let hafa4css = `
      .hafa4 { 
        width:500px !important;
    }
    .hafa4 body, table{
        font-size: 12px;
    }
      `
  
 
   
    return (
      <div className='print-source' >
           {/* Print  DOCUMENT */}

                 {/* {   A4 Print */
                    institution != null &&  institution.pro_print_type == 'a4'?(
                      <html lang="en">
                    <head>
                       <meta charset="UTF-8" />
                        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                        <meta http-equiv="X-UA-Compatible" content="ie=edge" />
                        <title>SALES  VOUCHER</title>
                        <style >
                           {a4css}
                        </style>
                    </head>
                    <tbody className="a4" style={{padding:'5px',width:'100%'}}>



                     

                     <table style={{width:'100%'}}>

                       <thead>
                           <tr>
                            <td><div style={{height:'10px'}}></div></td>
                            </tr> 



                            {
                          institution.pad_status == 'no' ?(<>
                          
                          <tr>
                           <td>
                            
                        <div className="invoice-head" style={{width:'100%'}}>
                            <div className="invoice-logo" style={{width:'20%',float:'left',marginLeft:'0%',marginTop:'5px',overflow:'hidden'}}>
                                  <img alt="logo" src={`${API_URL}/${institution.pro_logo}`} style={{width:'80px',height:'80px'}} />
                            </div>
                            <div style={{width:'1%'}}></div>
                            <div className="invoice-desc" style={{width:'72%',float:'left',marginLeft:'5px',marginTop:'5px'}}>
                                  <h2 style={{textAlign:'center',margin:'0px'}}>{institution.pro_name}</h2>
                                  <Font family='Roboto'  sans-serif>
                                  <p style={{textAlign:'center',color:'#222',fontSize:'16px',margin:'0px',padding:'0px',whiteSpace:'pre-line'}}>{institution.pro_desc}</p>

                                  </Font>
                            </div>
                        </div>
                           </td>
                           </tr>
                          
                          </>):<tr>
                            <td style={{height:'160px'}}></td>
                          </tr>
                        }
                       </thead>

                       <tbody>

                
                         
                         <tr>
                           <td>
                           <PrintAbleSection   authInfo={this.state.authInfo} sale_id={this.props.sale_id} institution={institution} padStatus={this.props.padStatus} />

                           </td>
                           
                         </tr>


                         <tr>
                           <td>
                           <div className="invoice-footer-bottom"  >
                      <div className="invoice-footer" style={{marginTop:'60px'}}>
                                <p style={{float:'left',marginLeft:'20px',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Received By</p>
                           
                                 <p style={{float:'right',marginRight:'25px',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Authorized By</p>
                      </div>


                      <div className="invoice-footer" >
                       
                            <p style={{float:'left',fontSize:'12px',marginLeft:'20px',color:'#222',fontWeight:'bold'}}>
                                Print Date  :  {moment().format(dateFormat)} 
                           </p>
                           <p style={{float:'right',marginRight:'12px',marginRight:'25px',fontSize:'10px',color:'#222',fontWeight:'bold',padding:'0px'}}>
                                Developed By : Soft Task
                           </p>
                      </div>
                      
                      </div>
                           </td>
                         </tr>
                       </tbody>

                       <tfoot >
                         <tr>
                         <td>
                           <div style={{height:'100px'}}></div>
                           </td>
                         </tr>
                       </tfoot>
                     </table>

                      
                    </tbody>
                    </html>
                     ):''
                 }
                    


                  {/* {  1/2 - A4   Print */
                    institution != null &&  institution.pro_print_type == '1/2a4'?(
                      <html lang="en">
                      <head>
                         <meta charset="UTF-8" />
                          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                          <meta http-equiv="X-UA-Compatible" content="ie=edge" />
                          <title>SALES  VOUCHER</title>
                          <style >
                             {hafa4css}
                          </style>
                      </head>
                      <tbody  className="hafa4" style={{padding:'5px'}}>

                       <table style={{width:'100%'}}>
  
                         <thead>
                            <tr>
                              <td><div style={{height:'10px'}}></div></td>
                              </tr>

                              {
                            institution.pad_status == 'no' ?(<>
                            
                            <tr>
                           <td>
                            
                           <div className="invoice-head" style={{width:'100%'}}>
                            <div className="invoice-logo" style={{width:'20%',float:'left',marginLeft:'0%',marginTop:'5px',overflow:'hidden'}}>
                                  <img alt="logo" src={`${API_URL}/${institution.pro_logo}`} style={{width:'80px',height:'80px'}} />
                            </div>
                            <div style={{width:'1%'}}></div>
                            <div className="invoice-desc" style={{width:'72%',float:'left',marginLeft:'5px',marginTop:'5px'}}>
                                  <h2 style={{textAlign:'center',margin:'0px'}}>{institution.pro_name}</h2>
                                  <Font family='Roboto'  sans-serif>
                                  <p style={{textAlign:'center',color:'#222',fontSize:'16px',margin:'0px',padding:'0px',whiteSpace:'pre-line'}}>{institution.pro_desc}</p>

                                  </Font>
                            </div>
                        </div>
                           </td>
                           </tr>
                            
                            </>):''
                          }
                         </thead>
  
                         <tbody>

                        
                      
                           <tr>
                             <td>
                             <PrintAbleSection   authInfo={this.state.authInfo} sale_id={this.props.sale_id} institution={institution} padStatus={this.props.padStatus} />
  
                             </td>
                             
                           </tr>
  
  
                           <tr>
                             <td>
                             <div className="invoice-footer-bottom "  >
                        <div className="invoice-footer" style={{marginTop:'60px'}}>
                                  <p style={{float:'left',marginLeft:'20px',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Received By</p>
                             
                                   <p style={{float:'right',marginRight:'25px',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Authorized By</p>
                        </div>
  
  
                        <div className="invoice-footer " >
                         
                              <p style={{float:'left',fontSize:'12px',marginLeft:'20px',color:'#222',fontWeight:'bold'}}>
                                  Print Date  :  {moment().format(dateFormat)} 
                             </p>
                             <p style={{float:'right',marginRight:'12px',marginRight:'25px',fontSize:'10px',color:'#222',fontWeight:'bold',padding:'0px'}}>
                                  Developed By : Soft Task 
                             </p>
                        </div>
                        
                        </div>
                             </td>
                           </tr>
                         </tbody>
  
                         <tfoot >
                           <tr>
                           <td>
                             <div style={{height:'100px'}}></div>
                             </td>
                           </tr>
                         </tfoot>
                       </table>
  
                        
                      </tbody>
                      </html>
                     ):''
                 }


                    
           
      </div>
    );
  }
}

let SalesVoucher = ({authInfo,sale_id,institution})=>{
  let componentRef = useRef()


  let padStatus = institution != null ? institution.pad_status : ''

  let padStatusAction = async (status)=>{



    await axios.post(`${API_URL}/api/save-pad-status`,{padStatus:status},{headers:{'auth-token':authInfo.token}}).then(res=>{
        window.location.reload()
  })

   }


  return (
    <>
            <Grid container>
                  <Grid item xs={12} sm={11} className={"invoice-section"}>
                    {/* Main Grid Start */}


                    {
institution != null? (<>




                      {institution.pad_status == 'yes'?(<>
                      
                      <p style={{    background: 'green',
                    display: 'table-cell',
    fontSize: '18px',
    borderRadius: '7px',
    padding: '3px',
    color: 'white',cursor:'pointer'}} onClick={()=>padStatusAction('no')}>AUTO PAD ?</p>
                      
                      </>):<>
                      

                      <p style={{    background: 'green',
                    display: 'table-cell',
    fontSize: '18px',
    borderRadius: '7px',
    padding: '3px',
    color: 'white',cursor:'pointer'}} onClick={()=>padStatusAction('yes')}>CUSTOM PAD ?</p>


                      </>
}

                      </>):''

                    }
                

                    <>
                    <ReactToPrint
                      trigger={() => <PrintIcon  style={{cursor:'pointer',marginLeft: '18px'}} />}
                      content={() => componentRef}
                      onBeforePrint = {() => componentRef}
                    />

    <ComponentToPrint ref={el => (componentRef = el)} authInfo={authInfo} sale_id={sale_id} institution={institution} />


                    <PrintAbleSection  authInfo={authInfo} sale_id={sale_id} institution={institution} padStatus={padStatus} />
                  </>
                  </Grid>
            </Grid>
    </>
  )
}



  const mapStateToPops = (state)=>{
    return {
      authInfo:state.authInfoReducer
    }
}
export default connect(mapStateToPops,{})(SalesVoucher);
