import React, { useState,useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import '../../commons/voucher.css'
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')
const VoucherBody = ({salesOrderData,authInfo})=>{
    const classes = useStyles();
  

         let [salesOrder,salesOrderSet] = useState([])
          let [salesOrderDetails,salesOrderDetailsSet] = useState([])

          let [is_serial,is_serial_set] = useState('')
          let [is_cal_type,is_cal_type_set] = useState('')

        useEffect(()=>{
            salesOrderSet(salesOrderData)
            
            is_serial_set(salesOrderData.is_serial)
            is_cal_type_set(salesOrderData.is_cal_type)

            if(salesOrderData.details!=undefined){
                salesOrderDetailsSet(salesOrderData.details)
            }
            },[salesOrderData])

         


      return(
          <>
        <table className={'invoice-table'}> 
          <thead>
              <tr>
                {/* <td  align="left"  width="1%">SL</td> */}
                <td align="left" width="25%"> Desc.</td>
            
              <td align="right" width="10%">Total</td>
              </tr>
                
             
          </thead>
          <tbody>
        
                    {
                        salesOrderDetails.map((item,ind)=>(<>

                        <tr>
                        <td  style={{textAlign:'left'}} colSpan={2}># {item.item_name}</td>
                        </tr>
                            
                              
                     <tr>
                  <td align="left"> {item.item_rate} X {item.qty_display}
                  </td>
                  
              
             
                
                  <td align="right">{format(parseFloat(item.item_total).toFixed(0))}</td>

                                </tr>
                        </>))
                    }
          
                
           

               
              

              
          </tbody>
        </table>
          </>
      )
}


const useStyles = makeStyles((theme) => ({
    
    
  }));

export default VoucherBody;
