import React, { useState,useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import '../../commons/voucher.css'
import {currentDateTime,convertNumberToWords, dateTimeFormat,dateFormat} from '../../../lib/functions'

import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')
const VoucherBody = ({employeeOrderData,authInfo})=>{
    const classes = useStyles();
  

         let [salesOrder,salesOrderSet] = useState([])


        useEffect(()=>{
            salesOrderSet(employeeOrderData)
            
          
           
            },[employeeOrderData])

         


      return(
          <>
                                <h3>Attendance Summary : </h3>

        <table className={'invoice-table'}> 
          <thead>
              <tr>
                <td  align="center"  width="10%">Day of The Month</td>
                <td  align="center"  width="10%">Precent Days</td>
                <td align="center" width="15%">Leave with Pay</td>
                <td align="center" width="15%">Leave without Pay</td>
                <td align="center" width="10%">No. of Days Payable </td>
              </tr>
                
             
          </thead>
          <tbody>
        
                  
          
          <tr>
                <td  align="center"  width="1%">{ salesOrder.getMonthDay }</td>
                <td  align="center"  width="1%">{ salesOrder.precent_days }</td>
                <td align="center" width="15%">{ salesOrder.leave_with_pay_days }</td>
                <td align="center" width="15%">{ salesOrder.leave_without_pay_days }</td>
                <td align="center" width="10%">{ salesOrder.payable_days }</td>
              </tr>
           

               
              

              
          </tbody>
        </table>


        <h3 style={{margin:'0px',padding:'0px',marginTop:'15px'}}>Salary Summary : </h3>
        <h4 style={{margin:'0px',padding:'0px',marginBottom:'3px'}}> Monthly Basic Salary : { format(parseFloat(salesOrder.salary).toFixed(2))  } {authInfo.currency}, Target : {format(parseFloat(salesOrder.target_amount).toFixed(2))} {authInfo.currency}, Commission % : {salesOrder.commission_per} </h4>

        <table className={'invoice-table'}> 
          <thead>
              <tr>
                <td  align="center"  width="10%"> Basic Salary </td>
                <td  align="center"  width="10%">Conveyance</td>
                <td align="center" width="15%">Overtime</td>
                <td align="center" width="15%">Others Commission</td>
                <td align="center" width="15%">Sales Commission</td>
                <td align="center" width="10%">Bonus</td>
                <td align="center" width="10%">TA</td>
                <td align="center" width="10%">DA</td>
                <td align="center" width="10%">MA</td>
                <td align="center" width="10%">Total</td>
              </tr>
          </thead>
          <tbody>
        
                  
          
          <tr>
                <td  align="center"  width="1%">{ format(parseFloat(salesOrder.basic_payable_salary).toFixed(2)) }</td>
                <td  align="center"  width="1%">{ format(parseFloat(salesOrder.conveyance).toFixed(2)) }</td>
                <td align="center" width="15%">{  format(parseFloat(salesOrder.overtime).toFixed(2)) }</td>
                <td align="center" width="15%">{   format(parseFloat(salesOrder.commission).toFixed(2))}</td>
                <td align="center" width="15%">{   format(parseFloat(salesOrder.sales_commission).toFixed(2))}</td>
                <td align="center" width="10%">{   format(parseFloat(salesOrder.bonus).toFixed(2))}</td>
                <td align="center" width="10%">{   format(parseFloat(salesOrder.ta).toFixed(2))}</td>
                <td align="center" width="10%">{   format(parseFloat(salesOrder.da).toFixed(2))}</td>
                <td align="center" width="10%">{   format(parseFloat(salesOrder.ma).toFixed(2))}</td>
                <td align="center" width="10%">{ format(parseFloat(
                  parseFloat(salesOrder.basic_payable_salary) + parseFloat(salesOrder.conveyance) +
                  + parseFloat(salesOrder.overtime) + parseFloat(salesOrder.commission) + parseFloat(salesOrder.sales_commission) + parseFloat(salesOrder.bonus) + parseFloat(salesOrder.ta) + parseFloat(salesOrder.da) + + parseFloat(salesOrder.ma)
                ).toFixed(2)) 
                 }</td>
              </tr>

          </tbody>
        </table>



        <h3>Deduction :  </h3>

        <table className={'invoice-table'}> 
          <thead>
              <tr>
                <td  align="center"  width="10%">Basic Salary (Paid)</td>
                <td  align="center"  width="10%"> Advance Salary (Paid)</td>
                <td align="center" width="15%">Salary Deduction</td>
                <td align="center" width="15%">Others Payment</td>
                <td align="center" width="10%">Total</td>
              </tr>
          </thead>
          <tbody>
        
                  
          
          <tr>
                <td  align="center"  width="1%">{ format(parseFloat(salesOrder.basic_salary_paid).toFixed(2))  }</td>
                <td  align="center"  width="1%">{ format(parseFloat(salesOrder.advance_salary).toFixed(2))  }</td>
                <td align="center" width="15%">{ format(parseFloat(salesOrder.deduction_salary).toFixed(2)) }</td>
                <td align="center" width="15%">{ format(parseFloat(
                  parseFloat(salesOrder.conveyance) +
                  + parseFloat(salesOrder.overtime) + parseFloat(salesOrder.commission) + 
                  parseFloat(salesOrder.sales_commission) + parseFloat(salesOrder.bonus) + 
                  parseFloat(salesOrder.ta) + parseFloat(salesOrder.da) + + parseFloat(salesOrder.ma)
                ).toFixed(2)) }</td>
                <td align="center" width="10%">{ format(parseFloat(
                  parseFloat(salesOrder.advance_salary) + 
                  + parseFloat(salesOrder.deduction_salary) 
                  + parseFloat(salesOrder.basic_salary_paid)
                  + parseFloat(salesOrder.conveyance) +
                  + parseFloat(salesOrder.overtime) + parseFloat(salesOrder.commission) + 
                  parseFloat(salesOrder.sales_commission) + parseFloat(salesOrder.bonus) + 
                  parseFloat(salesOrder.ta) + parseFloat(salesOrder.da) + + parseFloat(salesOrder.ma)
                ).toFixed(2)) 
                 }</td>
              </tr>

          </tbody>
        </table>



        <Grid container>

<Grid xs={6} sm={6} style={{marginTop: '5px'}}>
      <table style={{width:'100%',paddingRight: '20px'}}>

      
          

      </table>
</Grid>
<Grid xs={6} sm={6} style={{marginTop: '2px'}}>
          <table style={{width:'100%'}}>
            <tr>
              <td style={{color: '#222',fontWeight:'bold'}}>Salary Payable  :  ( + ) </td>
              <td style={{textAlign:"right",color: '#222',fontWeight:'bold'}}>  {format(parseFloat(
                  parseFloat(salesOrder.basic_payable_salary) + parseFloat(salesOrder.conveyance) +
                  + parseFloat(salesOrder.overtime) + parseFloat(salesOrder.commission) + parseFloat(salesOrder.bonus) + parseFloat(salesOrder.ta) 
                  + parseFloat(salesOrder.da)+ parseFloat(salesOrder.ma) 
                ).toFixed(2)) } </td>
            </tr>
      
           
            <tr>
              <td style={{color: '#222',fontWeight:'bold'}}>Paid : ( - ) </td>
              <td style={{textAlign:"right",color: '#222',fontWeight:'bold'}}>  {format(parseFloat(
                  parseFloat(salesOrder.advance_salary)  +
                  + parseFloat(salesOrder.deduction_salary) 
                  + parseFloat(salesOrder.basic_salary_paid)
                  + parseFloat(salesOrder.conveyance) +
                  + parseFloat(salesOrder.overtime) + parseFloat(salesOrder.commission) + 
                  parseFloat(salesOrder.sales_commission) + parseFloat(salesOrder.bonus) + 
                  parseFloat(salesOrder.ta) + parseFloat(salesOrder.da) + + parseFloat(salesOrder.ma)
                ).toFixed(2)) } </td>
            </tr>
           
            <tr >
            <td style={{width:'100%',borderTop: '1px solid #222'}}></td>
              <td style={{width:'100%',borderTop: '1px solid #222'}}></td>
            </tr>
            <tr>
              <td style={{color: '#222',fontWeight:'bold'}}>Current Payable   : {authInfo.currency}</td> 
              <td style={{textAlign:"right",color: '#222',fontWeight:'bold'}}>  { format(parseFloat(salesOrder.payable_salary).toFixed(2))} </td>
            </tr>

          

          

      </table>
</Grid>

<Grid xs={12} sm={12}>
      <p style={{color: '#222'}}>In Word of Payable Amount : {convertNumberToWords(salesOrder.payable_salary)} </p>
</Grid>

</Grid>


          </>
      )
}


const useStyles = makeStyles((theme) => ({
    
    
  }));

export default VoucherBody;
